import React from 'react'

const Loading = () => {
  return (
    <React.Fragment>
      <section className="container">
        <div className="loading__wrapper">
          <div className="loading__circle"></div>
        </div>
      </section>
    </React.Fragment>
  )
}

export { Loading as default }
