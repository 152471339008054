const url = 'https://hotelsquash.sk/wordpress/wp-json/wp/v2/'
const urlContactForm7 =
  'https://hotelsquash.sk/wordpress/wp-json/contact-form-7/v1/contact-forms/2609/feedback'
// const urlHome = 'https://localhost:3000/'
const queryStringImages = 'media?_fields=media_details&per_page=100&search='
const searchImagesStrings = {
  centrum: {
    izby: {
      apartman: 'centrum/foto/apartman',
      manzelska: 'centrum/foto/dvojlozkova-izba-manzelska-postel',
      jednolozkoDvojlozko: 'centrum/foto/dvojlozkova-a-jednolozkova-izba',
    },
    exterier: 'centrum/foto/exterier',
    restauraciaAKaviaren: 'centrum/foto/restauracia-a-kaviaren',
    gastronomia: 'centrum/foto/gastronomia',
    wellness: 'centrum/foto/wellness',
    sport: 'centrum/foto/sport',
    spolocenskeAFiremneAkcie: 'centrum/foto/spolocenske-a-firemne-akcie',
  },
  dependance: {
    izby: {
      apartman: 'dependance/foto/apartman',
      izba_c_1: 'dependance/foto/izba-c-1',
      izba_c_2: 'dependance/foto/izba-c-2',
      izba_c_3: 'dependance/foto/izba-c-3',
      izba_c_4: 'dependance/foto/izba-c-4',
    },
    interier: 'dependance/foto/interier',
    exterier: 'dependance/foto/exterier',
  },
}
const queryStringNavigation = 'navigacia?slug=navigacia'
const queryStringHotel = 'hotel?slug=hotel-'
const queryStringIzby = 'izby?slug='
const queryStringGastronomia = 'gastronomia?slug=gastronomia'
const queryStringJedalnyListok = 'gastronomia?slug=jedalny-listok'
const queryStringNapojovyListok = 'gastronomia?slug=napojovy-listok'
const queryStringVinnaKarta = 'gastronomia?slug=vinna-karta'
const queryStringDenneMenuFilter = 'denne_menu?_fields=acf,slug'
const queryStringDenneMenu = 'denne_menu?slug='
const queryStringSportWellness = 'sport_wellness?slug=sport-wellness-a-relax-'
const queryStringCennik = 'cennik?slug=cennik'
const queryStringCennikWellnessASport = 'cennik?slug=cennik-wellness-a-sport'
const queryStringSpolocenskeAkcie =
  'spolocenske_akcie?slug=spolocenske-a-firemne-akcie-centrum'
const queryStringVolnyCasVOkoli = 'volny_cas_v_okoli?slug=volny-cas-v-okoli'
const queryStringFoto = 'foto?slug=foto-'
const queryStringKontakt = 'kontakt?slug=kontakt'
const queryStringRezervacia = 'rezervacia?slug=rezervacia'
const queryStringFooter = 'footer?slug=footer-'

export {
  url,
  urlContactForm7,
  // urlHome,
  queryStringImages,
  searchImagesStrings,
  queryStringNavigation,
  queryStringHotel,
  queryStringIzby,
  queryStringDenneMenuFilter,
  queryStringDenneMenu,
  queryStringSportWellness,
  queryStringCennik,
  queryStringCennikWellnessASport,
  queryStringSpolocenskeAkcie,
  queryStringVolnyCasVOkoli,
  queryStringFoto,
  queryStringKontakt,
  queryStringFooter,
  queryStringGastronomia,
  queryStringJedalnyListok,
  queryStringNapojovyListok,
  queryStringRezervacia,
  queryStringVinnaKarta,
}
