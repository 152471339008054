import React from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// import useDispatchHotelName from '../../hooks/useDispatchHotelName'
import useFetchText from '../../hooks/useFetchText'
import useFetchImages from '../../hooks/useFetchImages'
import RoomCard from '../RoomCard'
import CennikHotelSingle from '../Cenniky/CennikHotelSingle'
import {
  queryStringCennik,
  queryStringHotel,
  queryStringIzby,
  queryStringImages,
  searchImagesStrings,
} from '../../wordpress/wordpress'
import NavigationSecondary from '../Navigations/NavigationSecondary'
import SectionFotoPrimary from '../Sections/SectionFotoPrimary'
import SectionText from '../Sections/SectionText'
import Button from '../Button'
import ReservationButton from '../ReservationButton'
import { selectRoom } from '../../actions/reservations'
import Underline from '../Utils/Underline'
import Loading from '../Utils/Loading'

const Room = () => {
  const { pathname } = useLocation()
  const hotelName = pathname.substring(pathname.lastIndexOf('-') + 1)
  // useDispatchHotelName(hotelName)
  const hotelText = useFetchText(`${queryStringHotel}${hotelName}`)
  const language = useSelector((state) => state.language)
  const cennik = useFetchText(queryStringCennik)

  // Define which rows in Cennik table should be rendered in bold
  // Index is starting from number 1 (not 0)
  // Match karta_izby
  const renderSwitch = (pathname) => {
    switch (pathname) {
      case '/apartman-centrum':
      case '/en/apartman-centrum':
        return [[1, 2], 'karta_izby_1', 'apartman']
      case '/jednolozkova-izba-centrum':
      case '/en/jednolozkova-izba-centrum':
        return [[3], 'karta_izby_2', 'jednolozkoDvojlozko']
      case '/dvojlozkova-izba-rozdelene-lozka-centrum':
      case '/en/dvojlozkova-izba-rozdelene-lozka-centrum':
        return [[4], 'karta_izby_3', 'jednolozkoDvojlozko']
      case '/dvojlozkova-izba-manzelska-postel-centrum':
      case '/en/dvojlozkova-izba-manzelska-postel-centrum':
        return [[5, 6], 'karta_izby_4', 'manzelska']
      case '/apartman-dependance':
      case '/en/apartman-dependance':
        return [[1, 3], 'karta_izby_1', 'apartman']
      case '/izba-c-1-dependance':
      case '/en/izba-c-1-dependance':
        return [[4, 5], 'karta_izby_2', 'izba_c_1']
      case '/izba-c-2-dependance':
      case '/en/izba-c-2-dependance':
        return [[4, 5], 'karta_izby_3', 'izba_c_2']
      case '/izba-c-3-dependance':
      case '/en/izba-c-3-dependance':
        return [[4, 5], 'karta_izby_4', 'izba_c_3']
      case '/izba-c-4-dependance':
      case '/en/izba-c-4-dependance':
        return [[4, 5], 'karta_izby_5', 'izba_c_4']
      default:
        return false
    }
  }
  const [strongTextIndex, karta_izby, imagesQueryString] = renderSwitch(
    pathname
  )
  const text = useFetchText(
    `${queryStringIzby}${pathname.replace(/\/en\/|\//, '')}`
  )

  const [imagesArr, namesArr] = useFetchImages(queryStringImages, [
    searchImagesStrings[hotelName].izby[
      imagesQueryString
    ] /* build query string for the correct room images*/,
  ])
  const images = imagesArr[namesArr]
  // Dispatch room name. it is used by Reservacia Page to populate room checkboxes.
  const dispatch = useDispatch()
  const dispatchSelectRoom = () => {
    if (text.isLoaded) {
      dispatch(
        selectRoom(
          `${text.nadpis} ${hotelName[0].toUpperCase()}${hotelName.substring(
            1
          )}`
        )
      )
    }
  }
  return (
    <React.Fragment>
      {hotelText.isLoaded ? (
        <React.Fragment>
          <NavigationSecondary
            text={Object.values(hotelText.karty_izieb).map((el) => {
              return {
                nadpis: el.nadpis,
                slug: el.slug,
              }
            })}
            samePage={false}
            hotelName={hotelName}
          />
          {text.isLoaded ? <SectionText text={text} /> : <Loading />}
        </React.Fragment>
      ) : (
        <Loading />
      )}

      {hotelText.isLoaded && images.length ? (
        <RoomCard
          text={hotelText.karty_izieb[karta_izby]}
          images={images}
          hotelName={hotelName}
        />
      ) : (
        <Loading />
      )}
      <Underline />
      <section className="section container">
        {text.isLoaded && (
          <ReservationButton
            dispatchSelectRoom={dispatchSelectRoom}
            link={`rezervacia-${hotelName}`}
            buttonText={text.rezervacia_izby_tlacitko}
          />
        )}
      </section>
      {cennik.isLoaded ? (
        <CennikHotelSingle
          nadpis={cennik.nadpis}
          text={
            hotelName === 'centrum'
              ? cennik.cennik_centrum
              : cennik.cennik_dependance
          }
          strongTextIndex={strongTextIndex}
        />
      ) : (
        <Loading />
      )}
      <section className="section container">
        {text.isLoaded && (
          <React.Fragment>
            <h1>{text.reklamacny_a_hotelovy_poriadok.nadpis}</h1>
            <Underline />
            <div className="row button__wrapper">
              <Button
                link={`/pdf/reklamacny-poriadok-${language.toLowerCase()}.pdf`}
                buttonText={
                  text.reklamacny_a_hotelovy_poriadok
                    .reklamacny_poriadok_tlacitko
                }
              />
              <Button
                link={`/pdf/hotelova-karta-${language.toLowerCase()}.pdf`}
                buttonText={
                  text.reklamacny_a_hotelovy_poriadok.hotelovy_poriadok_tlacitko
                }
              />
            </div>
          </React.Fragment>
        )}
      </section>

      {images.length ? (
        <SectionFotoPrimary
          text={{ nadpis: text.fotogaleria_nadpis }}
          imagesQueryString={
            searchImagesStrings[hotelName].izby[imagesQueryString]
          }
        />
      ) : (
        <Loading />
      )}
    </React.Fragment>
  )
}

export { Room as default }
