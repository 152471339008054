import React from 'react'
import marked from 'marked'
import parse from 'html-react-parser'
import Underline from '../Utils/Underline'

const SectionTextImageInRows = ({ text, imageSrc }) => {
  return (
    <section className="section container">
      {text.nadpis && (
        <h1 className="heading-1" id={text.nadpis}>
          {text.nadpis}
        </h1>
      )}

      <Underline />
      <div className="row">
        <div className="col-12 text-justify">
          {parse(marked(text.paragraf))}
        </div>
        <div className="col-12">
          <h3 className="heading-3">{text.popis_obrazku}</h3>
          <img src={imageSrc} alt="" className="img-fluid d-block mx-auto" />
        </div>
      </div>
    </section>
  )
}

export { SectionTextImageInRows as default }
