import React from 'react'
import Underline from '../Utils/Underline'

const CennikHotelSingle = ({ nadpis, text, strongTextIndex }) => {
  return (
    <React.Fragment>
      <section className="section container">
        <h1 className="heading-1" id={nadpis}>
          {nadpis}
        </h1>
        <Underline />
        <table className="cennik-table">
          <colgroup>
            <col className="cennik-table__col-1" />
            <col className="cennik-table__col-2" />
          </colgroup>
          <thead className="cennik-table__head">
            <tr>
              <th>{text.izby.nadpis}</th>
              <th></th>
            </tr>
          </thead>
          <tbody className="cennik-table__body">
            {Object.values(text.izby).map((el, index) => {
              return (
                <React.Fragment>
                  {el.izba && el.cena && (
                    <tr>
                      {(index >= strongTextIndex[0] &&
                        index <= strongTextIndex[1]) ||
                      index === strongTextIndex[0] ? (
                        <React.Fragment>
                          <td>
                            <strong>{el.izba}</strong>
                          </td>
                          <td>
                            <strong>{el.cena}</strong>
                          </td>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <td>{el.izba}</td>
                          <td>{el.cena}</td>
                        </React.Fragment>
                      )}
                    </tr>
                  )}
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
        <table className="cennik-table">
          <colgroup>
            <col className="cennik-table__col-1" />
            <col className="cennik-table__col-2" />
          </colgroup>
          <thead className="cennik-table__head">
            <tr>
              <th>{text.doplnkove_sluzby.nadpis}</th>
              <th></th>
            </tr>
          </thead>
          <tbody className="cennik-table__body">
            {Object.values(text.doplnkove_sluzby).map((el) => {
              if (el.sluzba && el.cena) {
                return (
                  <tr>
                    <td>{el.sluzba}</td>
                    <td>{el.cena}</td>
                  </tr>
                )
              } else {
                return null
              }
            })}
          </tbody>
        </table>
      </section>
    </React.Fragment>
  )
}

export { CennikHotelSingle as default }
