import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import navigationReducer from '../reducers/navigation'
import { textReducer } from '../reducers/texts'
import { imagesReducer } from '../reducers/images'
import reservationsReducer from '../reducers/reservations'
import languageReducer from '../reducers/language'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default () => {
  const store = createStore(
    combineReducers({
      navigation: navigationReducer,
      texts: textReducer,
      images: imagesReducer,
      reservations: reservationsReducer,
      language: languageReducer,
    }),
    composeEnhancers(applyMiddleware(thunk))
  )

  return store
}
