import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'
import { useFormikContext } from 'formik'
import moment from 'moment'
import 'moment/locale/sk'
import 'moment/locale/en-gb'

const MyDateRangePickerField = ({
  form: { setFieldValue, initialValues },
  placeholder,
  resetDate,
}) => {
  const language = useSelector((state) => state.language).toLowerCase()
  moment.locale(language)
  const formikContext = useFormikContext()
  const [startDate, setStartDate] = useState(initialValues.startDate)
  const [endDate, setEndDate] = useState(initialValues.endDate)
  const [focusedInput, setFocusedInput] = useState(null)
  useEffect(() => {
    setFieldValue('startDate', startDate)
    setFieldValue('endDate', endDate)
  }, [startDate, endDate])
  // reset react state of this component to clear the datepicker input values
  useEffect(() => {
    if (resetDate.shouldResetDate) {
      setStartDate(null)
      setEndDate(null)
      resetDate.setShouldResetDate(false)
    }
  }, [resetDate.shouldResetDate])

  return (
    <DateRangePicker
      startDate={startDate}
      startDateId="alksdjhgaskdjfkang2935nf2i"
      endDate={endDate}
      endDateId="fh877fho4i2nf82bg2o2784go"
      onDatesChange={({ startDate, endDate }) => {
        setStartDate(startDate)
        setEndDate(endDate)
      }}
      focusedInput={focusedInput}
      onFocusChange={(focusedInput) => {
        setFocusedInput(focusedInput)
      }}
      onClose={() => {
        formikContext.setTouched({ startDate: true, endDate: true })
      }}
      displayFormat={'DD.MM.yyyy'}
      startDatePlaceholderText={placeholder.datum_prichodu}
      endDatePlaceholderText={placeholder.datum_odchodu}
      numberOfMonths={1}
      hideKeyboardShortcutsPanel
    />
  )
}

export { MyDateRangePickerField as default }
