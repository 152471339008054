import React from 'react'

const ErrorWithSpacer = ({ error }) => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-4"></div>
        <div className="error col-12 col-md-6 col-lg-8">{error}</div>
      </div>
    </React.Fragment>
  )
}

export { ErrorWithSpacer as default }
