import React from 'react'

const CennikOther = ({ text }) => {
  return (
    <section className="section  container">
      <h2 className="heading-2 cennik__heading-2">{text.nadpis_tabulky}</h2>
      <table className="cennik-table">
        <colgroup>
          <col className="cennik-table__col-1" />
          <col className="cennik-table__col-2" />
        </colgroup>
        <thead className="cennik-table__head">
          <tr>
            <th>{text.hlavicka.sluzba}</th>
            <th>{text.hlavicka.cena}</th>
          </tr>
        </thead>
        <tbody className="cennik-table__body">
          {Object.values(text).map((el) => {
            if (el.stlpec_1 && el.stlpec_2) {
              return (
                <tr>
                  <td>{el.stlpec_1}</td>
                  <td>{el.stlpec_2}</td>
                </tr>
              )
            } else {
              return null
            }
          })}
        </tbody>
      </table>
    </section>
  )
}

export { CennikOther as default }
