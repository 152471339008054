import React from 'react'
import { Link } from 'react-router-dom'

const ReservationButton = ({ link, buttonText, dispatchSelectRoom }) => {
  return (
    <div className="row button__wrapper">
      <Link
        className="button button__link"
        to={link}
        onClick={dispatchSelectRoom}
      >
        {buttonText}
      </Link>
    </div>
  )
}

export { ReservationButton as default }
