import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, NavLink, useLocation } from 'react-router-dom'

import { setLanguageSlovak, setLanguageEnglish } from '../../actions/language'
import useFetchText from '../../hooks/useFetchText'
import useLanguageInPath from '../../hooks/useLanguageInPath'
import { queryStringNavigation } from '../../wordpress/wordpress'

const Navigation = () => {
  const text = useFetchText(queryStringNavigation)
  ///////////////////////////////////////////////////
  // Navigation toggle
  let prevScrollpos
  prevScrollpos = prevScrollpos || window.pageYOffset

  const toggleNavigation = () => {
    const currentScrollpos = window.pageYOffset
    const headerHtml = document.querySelector('header')

    const navigationHtml = document.querySelector('nav.js-navigation')
    const navbarShowHtml = document.querySelector('#navbarToggler')
    // Toggle whole navigation on scroll when:
    // 1) nav and header are defined and current scroll position is greater than header user height (toggle runs outside of header)
    // or
    // 2) nav is defined and header is not defined (toggle runs on whole page)
    // and do not toggle when
    // 1) navbar (navbar-collapse) is shown
    if (
      ((!!navigationHtml &&
        !!headerHtml &&
        currentScrollpos > headerHtml.clientHeight) ||
        (!!navigationHtml && !headerHtml)) &&
      !!navbarShowHtml &&
      !navbarShowHtml.classList.contains('show')
    ) {
      // Scrolling down -> hide nav
      if (currentScrollpos > prevScrollpos + 50) {
        navigationHtml.style.transform = 'translateY(-116px)'
        prevScrollpos = currentScrollpos
        // Scrolling up -> show nav
      } else if (currentScrollpos + 50 < prevScrollpos) {
        navigationHtml.style.transform = 'translateY(0)'
        prevScrollpos = currentScrollpos
      }
      // fix for navigation staying hidden over header when a specific scroll position is reached
    } else if (
      !!navigationHtml &&
      !!headerHtml &&
      currentScrollpos - 50 < headerHtml.clientHeight
    ) {
      navigationHtml.style.transform = 'translateY(0)'
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleNavigation)
    return () => {
      window.removeEventListener('scroll', toggleNavigation)
    }
  }, [prevScrollpos])

  // Navigation toggle END
  ///////////////////////////////////////////////////

  ///////////////////////////////////////////////////
  // Collapse navbar when pathname changes
  const { pathname } = useLocation()

  useEffect(() => {
    const navbarHtml = document.getElementById('navbarToggler')
    const btnHtml = document.getElementsByClassName(
      'hamburger-to-cross__btn'
    )[0]
    if (!!navbarHtml && !!btnHtml) {
      navbarHtml.classList.remove('show')
      btnHtml.classList.add('collapsed')
    }
  }, [pathname])

  // Collapse navbar when pathaname changes END
  ///////////////////////////////////////////////////

  ///////////////////////////////////////////////////
  // Language
  const dispatch = useDispatch()
  const language = useSelector((state) => state.language)
  useEffect(() => {
    const langInPath = pathname.search(/\/en/)
    if (langInPath === -1) {
      dispatch(setLanguageSlovak())
    } else if (langInPath === 0) {
      dispatch(setLanguageEnglish())
    }
  }, [])
  const navToLanguage = useLanguageInPath()

  const changeLanguage = () => {
    if (language === 'SK') {
      dispatch(setLanguageEnglish())
    } else if (language === 'EN') {
      dispatch(setLanguageSlovak())
    } else {
      dispatch(setLanguageSlovak())
    }
  }
  // Language END
  ///////////////////////////////////////////////////

  const hotelName = useSelector((state) => state.navigation)
  const shortPathname = pathname.slice(pathname.lastIndexOf('/'))
  return (
    <nav
      className={`navigation react-navigation-${hotelName} navbar navbar-expand-xl text-nowrap navbar-light js-navigation`}
    >
      <div className="navigation__box navigation__logo-wrapper navbar-brand">
        <NavLink to={`${navToLanguage}`} className="navigation__link">
          <img
            className="navigation__logo"
            src={`/images/logo/logo-${hotelName}.jpg`}
            alt="Logo"
          />{' '}
        </NavLink>
      </div>
      <button
        className="navbar-toggler collapsed hamburger-to-cross__btn"
        type="button"
        data-toggle="collapse"
        data-target="#navbarToggler"
        aria-controls="navbarToggler"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="hamburger-to-cross__icon"></span>
      </button>
      <div
        className="navigation__box navigation__box--main navbar-nav navbar-collapse collapse "
        id="navbarToggler"
      >
        <NavLink
          to={`${navToLanguage}/hotelsquash-${hotelName}`}
          className="navigation__link"
        >
          {text.isLoaded && text.link_1}
        </NavLink>
        <NavLink
          to={`${navToLanguage}/gastronomia-${hotelName}`}
          className="navigation__link"
        >
          {text.isLoaded && text.link_2}
        </NavLink>
        <NavLink
          to={`${navToLanguage}/sport-wellness-a-relax-${hotelName}`}
          className="navigation__link"
        >
          {text.isLoaded && text.link_3}
        </NavLink>
        {hotelName === `centrum` && (
          <NavLink
            to={`${navToLanguage}/spolocenske-a-firemne-akcie-centrum`}
            className="navigation__link"
          >
            {text.isLoaded && text.link_4}
          </NavLink>
        )}
        <NavLink
          to={`${navToLanguage}/foto-${hotelName}`}
          className="navigation__link"
        >
          {text.isLoaded && text.link_5}
        </NavLink>
        <NavLink
          to={`${navToLanguage}/volny-cas-v-okoli-${hotelName}`}
          className="navigation__link"
        >
          {text.isLoaded && text.link_6}
        </NavLink>
        <NavLink
          to={`${navToLanguage}/kontakt-${hotelName}`}
          className="navigation__link"
        >
          {text.isLoaded && text.link_7}
        </NavLink>

        <NavLink
          to={`${navToLanguage}/rezervacia-${hotelName}`}
          className="navigation__rezervacia"
        >
          {text.isLoaded && text.link_8}
        </NavLink>

        {language === 'EN' ? (
          <Link to={`${shortPathname}`} onClick={changeLanguage}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="30"
              viewBox="0 0 10800 7200"
              fill="#ee1c25"
            >
              <title>Slovensky</title>
              <defs>
                <clipPath id="c">
                  <path
                    id="s"
                    d="m4678 1746c0 0 56 561 56 1683-8 1146-865 1726-1494 2031-629-305-1486-885-1494-2031 0-1122 56-1683 56-1683z"
                  ></path>
                </clipPath>
              </defs>
              <g transform="scale(1200)">
                <path fill="#fff" d="m0 0h9v6H0z"></path>
                <path fill="#0b4ea2" d="m0 2h9v4H0z"></path>
                <path d="m0 4h9v2H0z"></path>
              </g>
              <use href="#s"></use>
              <path
                fill="#fff"
                d="m3113 4800V3397c-475 0-610 42-752 85a2323 2323 0 0 0 0-431c142 43 277 85 752 85v-390c-150 0-357 6-595 87a2323 2323 0 0 0 0-432c238 80 445 86 595 86 0-113-35-356-79-557a2323 2323 0 0 0 412 0c-44 201-79 444-79 557 150 0 357-6 595-86a2323 2323 0 0 0 0 432c-238-81-445-87-595-87v390c475 0 610-42 752-85a2323 2323 0 0 0 0 431c-142-43-277-85-752-85v1403z"
              ></path>
              <path
                clipPath="url(#c)"
                fill="#0b4ea2"
                d="m2463 5903a507 867 0 1 1 319-1541 584 1084 0 0 1 916 0 507 867 0 1 1 319 1541z"
              ></path>
              <use href="#s" fill="none" stroke="#fff" strokeWidth="108"></use>
            </svg>
          </Link>
        ) : (
          <Link to={`en${shortPathname}`} onClick={changeLanguage}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 60 30"
              width="45"
              height="30"
            >
              <clipPath id="s">
                <path d="M0,0 v30 h60 v-30 z"></path>
              </clipPath>
              <clipPath id="t">
                <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z"></path>
              </clipPath>
              <g clipPath="url(#s)">
                <path d="M0,0 v30 h60 v-30 z" fill="#012169"></path>
                <path
                  d="M0,0 L60,30 M60,0 L0,30"
                  stroke="#fff"
                  strokeWidth="6"
                ></path>
                <path
                  d="M0,0 L60,30 M60,0 L0,30"
                  clipPath="url(#t)"
                  stroke="#C8102E"
                  strokeWidth="4"
                ></path>
                <path
                  d="M30,0 v30 M0,15 h60"
                  stroke="#fff"
                  strokeWidth="10"
                ></path>
                <path
                  d="M30,0 v30 M0,15 h60"
                  stroke="#C8102E"
                  strokeWidth="6"
                ></path>
              </g>
            </svg>
          </Link>
        )}
      </div>
    </nav>
  )
}

export { Navigation as default }
