import React from 'react'
import marked from 'marked'
import parse from 'html-react-parser'
import Underline from '../Utils/Underline'
import {
  PiktogramyTooltipCentrum,
  PiktogramyTooltipDependance,
} from '../PiktogramyTooltip'

const SectionTextTwoColumns = ({ text, imageSrc, hotelName }) => {
  return (
    <section className="section container section-text-two-columns">
      <h1 className="heading-1" id={text.nadpis}>
        {text.nadpis}
      </h1>
      <Underline />
      {!!imageSrc && hotelName === 'centrum' ? (
        <div className="d-flex justify-content-center row">
          <div className="col-12 col-md-10 col-lg-8 col-xl-6 piktogramy-wrapper">
            <img
              src={imageSrc}
              width="800"
              height="187"
              className="img-fluid"
              alt="piktogramy"
              usemap="#piktogramy-map-centrum"
            />
            <PiktogramyTooltipCentrum />
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center row">
          <div className="col-12 col-md-10 col-lg-8 col-xl-6 piktogramy-wrapper">
            <img
              src={imageSrc}
              width="800"
              height="187"
              className="img-fluid"
              alt="piktogramy"
              usemap="#piktogramy-map-dependance"
            />
            <PiktogramyTooltipDependance />
          </div>
        </div>
      )}
      <div className="d-flex justify-content-center row section-text-two-columns__text">
        <div className="col-12 col-lg-6">
          <h3 className="heading-3 section-text-two-columns__heading">
            {text.hotel_centrum_ponuka.nadpis}
          </h3>
          {parse(marked(text.hotel_centrum_ponuka.paragraf))}
        </div>
        <div className="col-12 col-lg-6">
          <h3 className="heading-3 section-text-two-columns__heading">
            {text.hotel_dependance_ponuka.nadpis}
          </h3>
          {parse(marked(text.hotel_dependance_ponuka.paragraf))}
        </div>
      </div>
    </section>
  )
}

export { SectionTextTwoColumns as default }
