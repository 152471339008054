import React from 'react'
import { useField } from 'formik'

// Component to be used multiple times inside MyCheckboxGroup as children
const MyCheckbox = ({ children, ...props }) => {
  const [field, meta] = useField({
    ...props,
    type: 'checkbox',
    multiple: true,
  })
  return (
    <React.Fragment>
      <label className="checkbox form__checkbox-label">
        <input
          type="checkbox"
          className="form__checkbox-input"
          {...field}
          {...props}
        />
        {children}
      </label>
    </React.Fragment>
  )
}

export { MyCheckbox as default }
