import React from 'react'
import { Link } from 'react-router-dom'
import SimpleReactLightBox, { SRLWrapper } from 'simple-react-lightbox'
import Carousel from 'react-bootstrap/Carousel'
import Loading from './Utils/Loading'

const RoomCardDependance = ({ text, karta, images, hotelName, viac }) => {
  const renderSwitch = (text) => {
    switch (karta) {
      case 'karta_izby_1':
        return images.dependancefotoapartman.map((el) => {
          return (
            <Carousel.Item>
              <a href={el.media_details.sizes.full.source_url}>
                <img
                  src={el.media_details.sizes.large.source_url}
                  className="room-card__image"
                  alt=""
                />
              </a>
            </Carousel.Item>
          )
        })

      case 'karta_izby_2':
        return images.dependancefotoizbac1.map((el) => {
          return (
            <Carousel.Item>
              <a href={el.media_details.sizes.full.source_url}>
                <img
                  src={el.media_details.sizes.large.source_url}
                  className="room-card__image"
                  alt=""
                />
              </a>
            </Carousel.Item>
          )
        })
      case 'karta_izby_3':
        return images.dependancefotoizbac2.map((el) => {
          return (
            <Carousel.Item>
              <a href={el.media_details.sizes.full.source_url}>
                <img
                  src={el.media_details.sizes.large.source_url}
                  className="room-card__image"
                  alt=""
                />
              </a>
            </Carousel.Item>
          )
        })

      case 'karta_izby_4':
        return images.dependancefotoizbac3.map((el) => {
          return (
            <Carousel.Item>
              <a href={el.media_details.sizes.full.source_url}>
                <img
                  src={el.media_details.sizes.large.source_url}
                  className="room-card__image"
                  alt=""
                />
              </a>
            </Carousel.Item>
          )
        })
      case 'karta_izby_5':
        return images.dependancefotoizbac4.map((el) => {
          return (
            <Carousel.Item>
              <a href={el.media_details.sizes.full.source_url}>
                <img
                  src={el.media_details.sizes.large.source_url}
                  className="room-card__image"
                  alt=""
                />
              </a>
            </Carousel.Item>
          )
        })

      default:
        return false
    }
  }
  return (
    <SimpleReactLightBox>
      <div className="room-card row no-gutters">
        <div className="room-card__text-container col-12 col-lg-6">
          <div className="room-card__text-border">
            <h2 className="room-card__heading heading-2">
              <Link to={text.slug} className="room-card__heading--link">
                {text.nadpis}
              </Link>
            </h2>
            <p className="room-card__text">{text.paragraf}</p>
            <Link to={text.slug} className="room-card__link">
              {viac}
            </Link>
          </div>
        </div>
        <div className="room-card__image-container col-12 col-lg-6">
          <SRLWrapper>
            <Carousel controls={false} indicators={false}>
              {!!images.centrumfotodvojlozkovaizbamanzelskapostel &&
              images.centrumfotodvojlozkovaajednolozkovaizba &&
              images.centrumfotoapartman ? (
                renderSwitch(text)
              ) : (
                <Loading />
              )}
            </Carousel>
          </SRLWrapper>
        </div>
      </div>
    </SimpleReactLightBox>
  )
}

export { RoomCardDependance as default }
