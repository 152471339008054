import React from 'react'
import Header from '../Header'
import useFetchText from '../../hooks/useFetchText'
import { queryStringDenneMenu } from '../../wordpress/wordpress'
import useFilterDenneMenu from '../../hooks/useFilterDenneMenu'
import SectionDenneMenu from '../Sections/SectionDenneMenu'
import {
  queryStringGastronomia,
  queryStringJedalnyListok,
  queryStringNapojovyListok,
  queryStringVinnaKarta,
} from '../../wordpress/wordpress'
import SectionText from '../Sections/SectionText'
import SectionJedalnyListok from '../Sections/SectionJedalnyListok'
import SectionNapojovyListok from '../Sections/SectionNapojovyListok'
import SectionVinnaKarta from '../Sections/SectionVinnaKarta'
import NavigationSecondary from '../Navigations/NavigationSecondary'
import Loading from '../Utils/Loading'

const GastronomiaCentrum = () => {
  const imageName = 'gastronomia.jpg'
  const denneMenu = useFilterDenneMenu(queryStringDenneMenu)
  const gastronomia = useFetchText(queryStringGastronomia)
  const jedalnyListok = useFetchText(queryStringJedalnyListok)
  const napojovyListok = useFetchText(queryStringNapojovyListok)
  const vinnaKarta = useFetchText(queryStringVinnaKarta)
  return (
    <React.Fragment>
      <Header imageName={imageName} />
      {gastronomia.isLoaded &&
      denneMenu.isLoaded &&
      jedalnyListok.isLoaded &&
      napojovyListok.isLoaded &&
      vinnaKarta.isLoaded ? (
        <NavigationSecondary
          text={[
            gastronomia.hotel_squash_restaurant_centrum.nadpis,
            denneMenu.nadpis,
            jedalnyListok.jedalny_listok.nadpis,
            napojovyListok.nadpis,
            vinnaKarta.nadpis,
          ]}
        />
      ) : (
        <Loading />
      )}
      {gastronomia.isLoaded ? (
        <SectionText text={gastronomia.hotel_squash_restaurant_centrum} />
      ) : (
        <Loading />
      )}
      {denneMenu.isLoaded ? <SectionDenneMenu {...denneMenu} /> : <Loading />}
      {jedalnyListok.isLoaded ? (
        <SectionJedalnyListok text={jedalnyListok} />
      ) : (
        <Loading />
      )}
      {napojovyListok.isLoaded ? (
        <SectionNapojovyListok text={napojovyListok} />
      ) : (
        <Loading />
      )}
      {vinnaKarta.isLoaded ? (
        <SectionVinnaKarta text={vinnaKarta} />
      ) : (
        <Loading />
      )}
    </React.Fragment>
  )
}

const GastronomiaDependance = () => {
  const imageName = 'gastronomia.jpg'
  const denneMenu = useFilterDenneMenu(queryStringDenneMenu)
  const gastronomia = useFetchText(queryStringGastronomia)
  const jedalnyListok = useFetchText(queryStringJedalnyListok)
  const napojovyListok = useFetchText(queryStringNapojovyListok)
  const vinnaKarta = useFetchText(queryStringVinnaKarta)
  return (
    <React.Fragment>
      <Header imageName={imageName} />
      {gastronomia.isLoaded &&
      denneMenu.isLoaded &&
      jedalnyListok.isLoaded &&
      napojovyListok.isLoaded &&
      vinnaKarta.isLoaded ? (
        <NavigationSecondary
          text={[
            gastronomia.hotel_squash_restaurant_dependance.nadpis,
            denneMenu.nadpis,
            jedalnyListok.jedalny_listok.nadpis,
            napojovyListok.nadpis,
            vinnaKarta.nadpis,
          ]}
        />
      ) : (
        <Loading />
      )}
      {gastronomia.isLoaded ? (
        <SectionText text={gastronomia.hotel_squash_restaurant_dependance} />
      ) : (
        <Loading />
      )}
      {denneMenu.isLoaded ? <SectionDenneMenu {...denneMenu} /> : <Loading />}
      {jedalnyListok.isLoaded ? (
        <SectionJedalnyListok text={jedalnyListok} />
      ) : (
        <Loading />
      )}
      {napojovyListok.isLoaded ? (
        <SectionNapojovyListok text={napojovyListok} />
      ) : (
        <Loading />
      )}
      {vinnaKarta.isLoaded ? (
        <SectionVinnaKarta text={vinnaKarta} />
      ) : (
        <Loading />
      )}
    </React.Fragment>
  )
}

export { GastronomiaCentrum, GastronomiaDependance }
