const imagesReducerDefaults = {
  centrumfotoapartman: [],
  centrumfotodvojlozkovaajednolozkovaizba: [],
  centrumfotodvojlozkovaizbamanzelskapostel: [],
  dependancefotoapartman: [],
  dependancefotoizbac1: [],
  dependancefotoizbac2: [],
  dependancefotoizbac3: [],
  dependancefotoizbac4: [],
  dependancefotointerier: [],
  dependancefotoexterier: [],
  centrumfotoexterier: [],
  centrumfotorestauraciaakaviaren: [],
  centrumfotogastronomia: [],
  centrumfotowellness: [],
  centrumfotosport: [],
  centrumfotospolocenskeafiremneakcie: [],
}

const imagesReducer = (state = imagesReducerDefaults, { type, images }) => {
  switch (type) {
    case 'SET_IMAGES':
      return (state = {
        ...state,
        ...images,
      })
    default:
      return state
  }
}

export { imagesReducer }
