import React from 'react'

const PiktogramyTooltipCentrum = () => {
  return (
    <div className="piktogramy-tooltip--wrapper">
      <div className="piktogramy-tooltip">
        <span className="piktogramy-tooltip--span">Kaviareň</span>
      </div>
      <div className="piktogramy-tooltip">
        <span className="piktogramy-tooltip--span">Reštaurácia</span>
      </div>
      <div className="piktogramy-tooltip">
        <span className="piktogramy-tooltip--span">Bezlepkové jedlo</span>
      </div>
      <div className="piktogramy-tooltip">
        <span className="piktogramy-tooltip--span">
          Jednoposteľové izby 100x200
        </span>
      </div>
      <div className="piktogramy-tooltip">
        <span className="piktogramy-tooltip--span">Dvojlôžková izba</span>
      </div>
      <div className="piktogramy-tooltip">
        <span className="piktogramy-tooltip--span">Wellness</span>
      </div>
      <div className="piktogramy-tooltip">
        <span className="piktogramy-tooltip--span">LCD HD TV</span>
      </div>
      <div className="piktogramy-tooltip">
        <span className="piktogramy-tooltip--span">
          Domáci miláčikovia povolení
        </span>
      </div>
      <div className="piktogramy-tooltip">
        <span className="piktogramy-tooltip--span">Parkovanie zadarmo</span>
      </div>
      <div className="piktogramy-tooltip">
        <span className="piktogramy-tooltip--span">Garáž pre 3 motorky</span>
      </div>
      <div className="piktogramy-tooltip">
        <span className="piktogramy-tooltip--span">24h recepcia</span>
      </div>
      <div className="piktogramy-tooltip">
        <span className="piktogramy-tooltip--span">WiFi</span>
      </div>
      <div className="piktogramy-tooltip">
        <span className="piktogramy-tooltip--span">
          Priestory pre fajčiarov
        </span>
      </div>
      <div className="piktogramy-tooltip">
        <span className="piktogramy-tooltip--span">Squash kurt</span>
      </div>
      <div className="piktogramy-tooltip">
        <span className="piktogramy-tooltip--span">Posilovňa</span>
      </div>
      <div className="piktogramy-tooltip">
        <span className="piktogramy-tooltip--span">Práčka</span>
      </div>
    </div>
  )
}

const PiktogramyTooltipDependance = () => {
  return (
    <div className="piktogramy-tooltip--wrapper">
      <div className="piktogramy-tooltip piktogramy-tooltip--dependance">
        <span className="piktogramy-tooltip--span">
          Extra veľká manželská posteľ 200x200
        </span>
      </div>
      <div className="piktogramy-tooltip piktogramy-tooltip--dependance">
        <span className="piktogramy-tooltip--span">Bezbariérový apartmán</span>
      </div>
      <div className="piktogramy-tooltip piktogramy-tooltip--dependance">
        <span className="piktogramy-tooltip--span">
          Vhodné pre rodiny s deťmi
        </span>
      </div>
      <div className="piktogramy-tooltip piktogramy-tooltip--dependance">
        <span className="piktogramy-tooltip--span">Klimatizované izby</span>
      </div>
      <div className="piktogramy-tooltip piktogramy-tooltip--dependance">
        <span className="piktogramy-tooltip--span">LCD HD TV</span>
      </div>
      <div className="piktogramy-tooltip piktogramy-tooltip--dependance">
        <span className="piktogramy-tooltip--span">
          Domáci miláčikovia povolení
        </span>
      </div>
      <div className="piktogramy-tooltip piktogramy-tooltip--dependance">
        <span className="piktogramy-tooltip--span">Požičovňa bicyklov</span>
      </div>
      <div className="piktogramy-tooltip piktogramy-tooltip--dependance">
        <span className="piktogramy-tooltip--span">Parkovanie zadarmo</span>
      </div>
      <div className="piktogramy-tooltip piktogramy-tooltip--dependance">
        <span className="piktogramy-tooltip--span">
          Uzamykateľné parkovisko v súkromí
        </span>
      </div>
      <div className="piktogramy-tooltip piktogramy-tooltip--dependance">
        <span className="piktogramy-tooltip--span">24h recepcia</span>
      </div>
      <div className="piktogramy-tooltip piktogramy-tooltip--dependance">
        <span className="piktogramy-tooltip--span">WiFi</span>
      </div>
      <div className="piktogramy-tooltip piktogramy-tooltip--dependance">
        <span className="piktogramy-tooltip--span">Nefajčiarsky hotel</span>
      </div>

      <div className="piktogramy-tooltip piktogramy-tooltip--dependance">
        <span className="piktogramy-tooltip--span">Záhrada</span>
      </div>
      <div className="piktogramy-tooltip piktogramy-tooltip--dependance">
        <span className="piktogramy-tooltip--span">Gril na záhrade</span>
      </div>
    </div>
  )
}

export { PiktogramyTooltipCentrum, PiktogramyTooltipDependance }
