import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { Helmet } from 'react-helmet'
import AppRouter from '../../routers/AppRouter'
import { useSelector } from 'react-redux'

import Footer from '../Footer'
import Navigation from '../Navigations/Navigation'

export const history = createBrowserHistory()

const HotelSquashApp = () => {
  const hotelName = useSelector((state) => state.navigation)
  return (
    <Router history={history}>
      <Helmet>
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="6b09b508-d21b-4dc5-94d8-4af2a253e5ca"
          data-blockingmode="auto"
          type="text/javascript"
        ></script>
      </Helmet>
      {hotelName && <Navigation />}
      <AppRouter />
      {hotelName && <Footer />}
      <script
        id="CookieDeclaration"
        src="https://consent.cookiebot.com/6b09b508-d21b-4dc5-94d8-4af2a253e5ca/cd.js"
        type="text/javascript"
        async
      ></script>
    </Router>
  )
}

export default HotelSquashApp
