import React from 'react'
import parse from 'html-react-parser'
import Underline from '../Utils/Underline'

const SectionNapojovyListok = ({ text }) => {
  const napojovyListokArray = Object.values(text.skupiny)
  const idCollapseMain = text.nadpis.replace(/[/ ]/g, '')
  return (
    <section className="section container">
      <h1
        className="heading-1 collapse-icon-after"
        id={text.nadpis}
        data-toggle="collapse"
        data-target={`#${idCollapseMain}-collapse`}
        aria-expanded="false"
        aria-controls={`${idCollapseMain}-collapse`}
      >
        {text.nadpis}
      </h1>
      <Underline />

      <div className="collapse" id={`${idCollapseMain}-collapse`}>
        {napojovyListokArray.map((el, index) => {
          Object.defineProperty(el, 'nadpis', {
            enumerable: false,
          })
          const idCollapse = `${el.nadpis.replace(/[/ ]/g, '')}${index}`
          return (
            <React.Fragment>
              {index === 5 && (
                <h2 className="heading-2 heading-2__left-small-gap text-left">
                  {text.alko_napoje}
                </h2>
              )}
              <h3
                className="heading-3 collapse-icon-after text-left"
                data-toggle="collapse"
                data-target={`#${idCollapse}-collapse`}
                aria-expanded="false"
                aria-controls={`${idCollapse}-collapse`}
              >
                {el.nadpis}
              </h3>
              <div className="collapse" id={`${idCollapse}-collapse`}>
                {Object.values(el).map((el) => {
                  if (el.nazov_napoja === '') {
                    return null
                  } else {
                    return (
                      <React.Fragment>
                        <div className="row">
                          <p className="col-6">{parse(el.nazov_napoja)}</p>
                          <p className="col-6 text-right">{el.cena}</p>
                        </div>
                      </React.Fragment>
                    )
                  }
                })}
              </div>
            </React.Fragment>
          )
        })}
      </div>
    </section>
  )
}

export { SectionNapojovyListok as default }
