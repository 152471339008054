import { url } from '../wordpress/wordpress'

const setImages = (images = {}) => ({
  type: 'SET_IMAGES',
  images,
})

const fetchImages = (queryStringImages, searchImagesStringsArr) => {
  return (dispatch) => {
    const fetchData = async () => {
      try {
        const jsonData = await Promise.all(
          searchImagesStringsArr.map((el) => {
            return fetch(`${url}${queryStringImages}${el}`).then((res) =>
              res.json()
            )
          })
        )
        const objectData = await jsonData.reduce((acc, cur, index) => {
          let parsedArr = searchImagesStringsArr[index].replace(/-|\//g, '')
          acc[parsedArr] = cur
          return acc
        }, {})

        if (true) {
          dispatch(setImages(objectData))
        }
      } catch (error) {
        console.log('Error fetching data', error)
      }
    }
    fetchData()
  }
}

export { setImages, fetchImages }
