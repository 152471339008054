import React from 'react'
import useFetchText from '../../hooks/useFetchText'
import { queryStringFoto, searchImagesStrings } from '../../wordpress/wordpress'
import NavigationSecondary from '../Navigations/NavigationSecondary'
import SectionFotoPrimary from '../Sections/SectionFotoPrimary'
import Underline from '../Utils/Underline'
import Loading from '../Utils/Loading'

const FotoCentrum = () => {
  const hotelName = 'centrum'
  const text = useFetchText(`${queryStringFoto}${hotelName}`)
  return (
    <React.Fragment>
      {text.isLoaded ? (
        <React.Fragment>
          <NavigationSecondary
            text={[
              text.nadpis_1.nadpis,
              text.nadpis_2.nadpis,
              text.nadpis_3.nadpis,
              text.nadpis_4.nadpis,
              text.nadpis_5.nadpis,
              text.nadpis_6.nadpis,
              text.nadpis_7.nadpis,
            ]}
          />
          <SectionFotoPrimary
            text={text.nadpis_1}
            imagesQueryString={searchImagesStrings.centrum.exterier}
          />
          <SectionFotoPrimary
            text={text.nadpis_2}
            imagesQueryString={searchImagesStrings.centrum.restauraciaAKaviaren}
          />
          <h1 className="heading-1" id={text.nadpis_3.nadpis}>
            {text.nadpis_3.nadpis}
          </h1>
          <Underline />
          <SectionFotoPrimary
            text={{ nadpis: text.nadpis_3.podnadpis_1 }}
            imagesQueryString={searchImagesStrings.centrum.izby.apartman}
            headingH2={true}
          />
          <SectionFotoPrimary
            text={{ nadpis: text.nadpis_3.podnadpis_2 }}
            imagesQueryString={searchImagesStrings.centrum.izby.manzelska}
            headingH2={true}
          />
          <SectionFotoPrimary
            text={{ nadpis: text.nadpis_3.podnadpis_3 }}
            imagesQueryString={
              searchImagesStrings.centrum.izby.jednolozkoDvojlozko
            }
            headingH2={true}
          />
          <SectionFotoPrimary
            text={text.nadpis_4}
            imagesQueryString={searchImagesStrings.centrum.gastronomia}
          />
          <SectionFotoPrimary
            text={text.nadpis_5}
            imagesQueryString={searchImagesStrings.centrum.wellness}
          />
          <SectionFotoPrimary
            text={text.nadpis_6}
            imagesQueryString={searchImagesStrings.centrum.sport}
          />
          <SectionFotoPrimary
            text={text.nadpis_7}
            imagesQueryString={
              searchImagesStrings.centrum.spolocenskeAFiremneAkcie
            }
          />
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </React.Fragment>
  )
}

const FotoDependance = () => {
  const hotelName = 'dependance'
  const text = useFetchText(`${queryStringFoto}${hotelName}`)
  return (
    <React.Fragment>
      {text.isLoaded ? (
        <React.Fragment>
          <NavigationSecondary
            text={[
              text.nadpis_1.nadpis,
              text.nadpis_2.nadpis,
              text.nadpis_3.nadpis,
              text.nadpis_4.nadpis,
              text.nadpis_5.nadpis,
              text.nadpis_6.nadpis,
              text.nadpis_7.nadpis,
            ]}
          />
          <SectionFotoPrimary
            text={text.nadpis_1}
            imagesQueryString={searchImagesStrings.dependance.exterier}
          />
          <SectionFotoPrimary
            text={text.nadpis_2}
            imagesQueryString={searchImagesStrings.dependance.interier}
          />
          <h1 className="heading-1" id={text.nadpis_3.nadpis}>
            {text.nadpis_3.nadpis}
          </h1>
          <Underline />
          <SectionFotoPrimary
            text={{ nadpis: text.nadpis_3.podnadpis_1 }}
            imagesQueryString={searchImagesStrings.dependance.izby.izba_c_1}
            headingH2={true}
          />
          <SectionFotoPrimary
            text={{ nadpis: text.nadpis_3.podnadpis_2 }}
            imagesQueryString={searchImagesStrings.dependance.izby.izba_c_2}
            headingH2={true}
          />
          <SectionFotoPrimary
            text={{ nadpis: text.nadpis_3.podnadpis_3 }}
            imagesQueryString={searchImagesStrings.dependance.izby.izba_c_3}
            headingH2={true}
          />
          <SectionFotoPrimary
            text={{ nadpis: text.nadpis_3.podnadpis_4 }}
            imagesQueryString={searchImagesStrings.dependance.izby.izba_c_4}
            headingH2={true}
          />
          <SectionFotoPrimary
            text={{ nadpis: text.nadpis_3.podnadpis_5 }}
            imagesQueryString={searchImagesStrings.dependance.izby.apartman}
            headingH2={true}
          />
          <SectionFotoPrimary
            text={text.nadpis_4}
            imagesQueryString={searchImagesStrings.centrum.wellness}
          />
          <SectionFotoPrimary
            text={text.nadpis_5}
            imagesQueryString={searchImagesStrings.centrum.sport}
          />
          <SectionFotoPrimary
            text={text.nadpis_6}
            imagesQueryString={
              searchImagesStrings.centrum.spolocenskeAFiremneAkcie
            }
          />
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </React.Fragment>
  )
}

export { FotoCentrum, FotoDependance }
