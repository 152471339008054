import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { setHotelNameHome } from '../../actions/navigationActions'
import useLanguageInPath from '../../hooks/useLanguageInPath'
import {
  PiktogramyTooltipCentrum,
  PiktogramyTooltipDependance,
} from '../PiktogramyTooltip'

const Home = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setHotelNameHome())
  }, [])
  const languageInPath = useLanguageInPath()
  return (
    <section className="home">
      <Link to={`${languageInPath}/hotelsquash-centrum`} className="home__link">
        <img src="images/logo/logo-centrum.jpg" alt="" className="home__logo" />
        <div className="home__piktogramy-wrapper">
          <img
            src="images/piktogramy/piktogramy-centrum-cervene.jpg"
            width="800"
            height="187"
            className="home__piktogramy"
            alt="piktogramy"
            usemap="#piktogramy-map-centrum"
          />
          <img
            src="images/piktogramy/piktogramy-centrum-zlate.jpg"
            width="800"
            height="187"
            className="home__piktogramy home__piktogramy--zlate"
            alt="piktogramy"
            usemap="#piktogramy-map-centrum"
          />

          <PiktogramyTooltipCentrum />
        </div>
      </Link>
      <Link
        to={`${languageInPath}/hotelsquash-dependance`}
        className="home__link"
      >
        <img
          src="images/logo/logo-dependance.jpg"
          alt=""
          className="home__logo"
        />
        <div className="home__piktogramy-wrapper">
          <img
            src="images/piktogramy/piktogramy-dependance-modre.jpg"
            width="800"
            height="187"
            className="home__piktogramy"
            alt="piktogramy"
            usemap="#piktogramy-map-dependance"
          />
          <img
            src="images/piktogramy/piktogramy-dependance-zlate.jpg"
            width="800"
            height="187"
            className="home__piktogramy home__piktogramy--zlate"
            alt="piktogramy"
            usemap="#piktogramy-map-dependance"
          />
          <PiktogramyTooltipDependance />
        </div>
      </Link>
    </section>
  )
}

export default Home
