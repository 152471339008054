import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { filterDenneMenu } from '../actions/filterDenneMenu'

const useFilterDenneMenu = (endpointAPI) => {
  let endpointJS = endpointAPI.substring(0, endpointAPI.indexOf('?'))
  endpointJS = endpointJS.replace(/-|_/g, '')
  const text = useSelector((state) => state.texts[endpointJS])
  const dispatch = useDispatch()
  useEffect(() => {
    if (!text.isLoaded) {
      dispatch(filterDenneMenu(endpointAPI, endpointJS, dispatch))
    }
  }, [])
  return text
}

export { useFilterDenneMenu as default }
