import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchText } from '../actions/texts'

const useFetchText = (endpointAPI) => {
  // Detect language
  const language = useSelector((state) => state.language)
  // Extract part of the query string after 'slug='
  let endpointJS = endpointAPI.substring(endpointAPI.indexOf('=') + 1)
  // Remove '-' and '_'
  endpointJS = endpointJS.replace(/-|_/g, '')
  // Add 'en' suffix to endpointJS if language is english
  const addEndpointJSSuffix = (endpointJS, language) => {
    if (language === 'EN') {
      endpointJS = `${endpointJS}${language.toLowerCase()}`
      return endpointJS
    } else {
      return endpointJS
    }
  }
  // Add '-en' suffix to endpointAPI if language is english
  const addEndpointAPISuffix = (endpointAPI, language) => {
    if (language === 'EN') {
      endpointAPI = `${endpointAPI}-${language.toLowerCase()}`
      return endpointAPI
    } else {
      return endpointAPI
    }
  }
  endpointAPI = addEndpointAPISuffix(endpointAPI, language)
  endpointJS = addEndpointJSSuffix(endpointJS, language)
  const text = useSelector((state) => state.texts[endpointJS])
  const dispatch = useDispatch()
  useEffect(() => {
    // Dispatch only if text is not already loaded - prevent unnecessary api calls
    if (!text.isLoaded) {
      dispatch(fetchText(endpointAPI, endpointJS, dispatch))
    }
  }, [endpointAPI, endpointJS])
  return text
}

export { useFetchText as default }
