import React from 'react'
import marked from 'marked'
import parse from 'html-react-parser'
import Underline from '../Utils/Underline'
const SectionText = ({ text, classNames }) => {
  return (
    <section className={`section container ${!!classNames ? classNames : ''}`}>
      <h1 className="heading-1" id={text.nadpis}>
        {text.nadpis}
      </h1>
      <Underline />
      <div className="row">
        <div className="col-12 text-justify">
          {parse(marked(text.paragraf))}
        </div>
      </div>
    </section>
  )
}

export { SectionText as default }
