import { useSelector } from 'react-redux'

const useLanguageInPath = () => {
  const language = useSelector((state) => state.language)
  if (language === 'SK') {
    return ''
  } else if (language === 'EN') {
    return '/en'
  } else {
    return ''
  }
}

export { useLanguageInPath as default }
