import React from 'react'
import marked from 'marked'
import parse from 'html-react-parser'
import Underline from '../Utils/Underline'

const SectionTextImageMap = ({
  text,
  imageSrc,
  googleMap,
  sectionRowReverse,
}) => {
  return (
    <section className="section section-text-image-map container">
      {text.nadpis && (
        <h1 className="heading-1" id={text.nadpis}>
          {text.nadpis}
        </h1>
      )}

      <Underline />
      <div
        className={`row ${sectionRowReverse ? 'section__row--reverse' : ''}`}
      >
        <div className="col-12 col-lg-6 text-justify">
          {parse(marked(text.paragraf))}
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          <img
            src={imageSrc}
            alt=""
            className="img-fluid section-text-image-map__image"
          />
        </div>
        <div className="col-12 col-md-6 col-lg-3 section-text-image-map__map">
          <div
            className="section__map"
            dangerouslySetInnerHTML={{ __html: googleMap }}
          />
        </div>
      </div>
    </section>
  )
}

export { SectionTextImageMap as default }
