import React from 'react'
import parse from 'html-react-parser'
import Underline from '../Utils/Underline'

const SectionJedalnyListok = ({ text }) => {
  const jedalnyListokArray = Object.values(text.jedalny_listok.skupiny)
  const alergeny = text.alergeny
  const idCollapseMain = text.jedalny_listok.nadpis.replace(/ /g, '')

  const idCollapseAlergeny = alergeny.nadpis.replace(/ /g, '')
  return (
    <section className="section container">
      <h1
        className="heading-1 collapse-icon-after"
        id={text.jedalny_listok.nadpis}
        data-toggle="collapse"
        data-target={`#${idCollapseMain}-collapse`}
        aria-expanded="false"
        aria-controls={`${idCollapseMain}-collapse`}
      >
        {text.jedalny_listok.nadpis}
      </h1>
      <Underline />
      <div className="collapse" id={`${idCollapseMain}-collapse`}>
        {jedalnyListokArray.map((el, index) => {
          Object.defineProperty(el, 'nadpis', {
            enumerable: false,
          })
          const idCollapse = `${el.nadpis.replace(/ /g, '')}${index}`
          return (
            <React.Fragment>
              <h3
                className="heading-3 collapse-icon-after text-left"
                data-toggle="collapse"
                data-target={`#${idCollapse}-collapse`}
                aria-expanded="false"
                aria-controls={`${idCollapse}-collapse`}
              >
                {el.nadpis}
              </h3>

              <div className="collapse" id={`${idCollapse}-collapse`}>
                {Object.values(el).map((el) => {
                  if (el.nazov_jedla === '') {
                    return null
                  } else {
                    return (
                      <React.Fragment>
                        <div className="row">
                          <p className="col-6">{el.nazov_jedla}</p>
                          <p className="col-6 text-right">{el.cena}</p>
                          <p className="col-12">{el.alergeny}</p>
                        </div>
                      </React.Fragment>
                    )
                  }
                })}
              </div>
            </React.Fragment>
          )
        })}
        <React.Fragment>
          <h3
            className="heading-3 collapse-icon-after text-left"
            data-toggle="collapse"
            data-target={`#${idCollapseAlergeny}-collapse`}
            aria-expanded="false"
            aria-controls={`${idCollapseAlergeny}-collapse`}
          >
            {text.alergeny.nadpis}
          </h3>
          <div className="collapse" id={`${idCollapseAlergeny}-collapse`}>
            <div className="row">
              <p className="col-12">{alergeny.paragraf}</p>
            </div>
            <div className="row">
              <p className="col-6">{alergeny.priklad}</p>
              <p className="col-6">{alergeny.alergeny_slovom}</p>
            </div>
            <div className="row">
              <p className="col-6">{alergeny.jedlo}</p>
              <p className="col-6">{alergeny.alergeny_cislom}</p>
            </div>
            <div className="row">
              <p className="col-12">{alergeny.zlozenie}</p>
            </div>
            <h3 className="heading-3 text-left">
              {alergeny.oznacovanie_alergenov.nadpis}
            </h3>
            <div className="row">
              {Object.values(
                Object.defineProperty(
                  alergeny.oznacovanie_alergenov,
                  'nadpis',
                  { enumerable: false }
                )
              ).map((el) => {
                return <p className="col-12">{parse(el)}</p>
              })}
            </div>
          </div>
        </React.Fragment>
      </div>
    </section>
  )
}

export { SectionJedalnyListok as default }
