import React from 'react'
import SimpleReactLightBox, { SRLWrapper } from 'simple-react-lightbox'
import useFetchImages from '../../hooks/useFetchImages'
import { queryStringImages } from '../../wordpress/wordpress'
import Underline from '../Utils/Underline'
import Loading from '../Utils/Loading'

const SectionFotoPrimary = ({ text, imagesQueryString, headingH2 }) => {
  const [images, namesArr] = useFetchImages(queryStringImages, [
    imagesQueryString,
  ])
  return (
    <section className="section container gallery">
      {!!headingH2 ? (
        <h2 className="heading-2" id={text.nadpis}>
          {text.nadpis}
        </h2>
      ) : (
        <React.Fragment>
          <h1 className="heading-1" id={text.nadpis}>
            {text.nadpis}
          </h1>
          <Underline />
        </React.Fragment>
      )}

      <SimpleReactLightBox>
        {images[namesArr].length ? (
          <SRLWrapper options={{ settings: { slideAnimationType: 'slide' } }}>
            {images[namesArr].map((el, index) => {
              return (
                <figure
                  style={{ width: 200 + 'px', height: 200 + 'px' }}
                  className="gallery__figure"
                  key={index}
                >
                  <a
                    href={el.media_details.sizes.full.source_url}
                    data-attribute="SRL"
                  >
                    <img
                      src={el.media_details.sizes.medium.source_url}
                      className="gallery__image"
                      alt=""
                    />
                  </a>
                </figure>
              )
            })}
          </SRLWrapper>
        ) : (
          <Loading />
        )}
      </SimpleReactLightBox>
    </section>
  )
}

export { SectionFotoPrimary as default }
