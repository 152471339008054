import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  setHotelNameCentrum,
  setHotelNameDependance,
} from '../actions/navigationActions'
import { useLocation } from 'react-router-dom'

const useDispatchHotelName = () => {
  const { pathname } = useLocation()
  const hotelName = pathname.substring(pathname.lastIndexOf('-') + 1)
  const dispatch = useDispatch()
  useEffect(() => {
    if (hotelName === 'centrum') {
      dispatch(setHotelNameCentrum())
    } else if (hotelName === 'dependance') {
      dispatch(setHotelNameDependance())
    }
  }, [hotelName, dispatch])
}

export { useDispatchHotelName as default }
