import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import {
  HotelSquashCentrum,
  HotelSquashDependance,
} from '../components/Pages/HotelSquash'
import Home from '../components/Pages/Home'
import {
  GastronomiaCentrum,
  GastronomiaDependance,
} from '../components/Pages/Gastronomia'
import {
  SportWellnessARelaxCentrum,
  SportWellnessARelaxDependance,
} from '../components/Pages/SportWellnessARelax'
import { SpolocenskeAFiremneAkcieCentrum } from '../components/Pages/SpolocenskeAFiremneAkcie'
import { FotoCentrum, FotoDependance } from '../components/Pages/Foto'
import {
  VolnyCasVOkoliCentrum,
  VolnyCasVOkoliDependance,
} from '../components/Pages/VolnyCasVOkoli'
import { KontaktCentrum, KontaktDependance } from '../components/Pages/Kontakt'
import Rezervacia from '../components/Pages/Rezervacia'
import Room from '../components/Pages/Room'

import useDispatchHotelName from '../hooks/useDispatchHotelName'

const AppRouter = () => {
  useDispatchHotelName()
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <Switch>
      <Route path="/" component={Home} exact={true} />
      <Route path="/en" component={Home} exact={true} />
      <Route path="/hotelsquash-centrum" component={HotelSquashCentrum} />
      <Route path="/en/hotelsquash-centrum" component={HotelSquashCentrum} />
      <Route path="/gastronomia-centrum" component={GastronomiaCentrum} />
      <Route path="/en/gastronomia-centrum" component={GastronomiaCentrum} />
      <Route
        path="/sport-wellness-a-relax-centrum"
        component={SportWellnessARelaxCentrum}
      />
      <Route
        path="/en/sport-wellness-a-relax-centrum"
        component={SportWellnessARelaxCentrum}
      />
      <Route
        path="/spolocenske-a-firemne-akcie-centrum"
        component={SpolocenskeAFiremneAkcieCentrum}
      />
      <Route
        path="/en/spolocenske-a-firemne-akcie-centrum"
        component={SpolocenskeAFiremneAkcieCentrum}
      />
      <Route path="/foto-centrum" component={FotoCentrum} />
      <Route path="/en/foto-centrum" component={FotoCentrum} />
      <Route
        path="/volny-cas-v-okoli-centrum"
        component={VolnyCasVOkoliCentrum}
      />
      <Route
        path="/en/volny-cas-v-okoli-centrum"
        component={VolnyCasVOkoliCentrum}
      />
      <Route path="/kontakt-centrum" component={KontaktCentrum} />
      <Route path="/en/kontakt-centrum" component={KontaktCentrum} />
      <Route path="/rezervacia-centrum" component={Rezervacia} />
      <Route path="/en/rezervacia-centrum" component={Rezervacia} />
      <Route path="/rezervacia-dependance" component={Rezervacia} />
      <Route path="/en/rezervacia-dependance" component={Rezervacia} />

      <Route path="/hotelsquash-dependance" component={HotelSquashDependance} />
      <Route
        path="/en/hotelsquash-dependance"
        component={HotelSquashDependance}
      />
      <Route path="/gastronomia-dependance" component={GastronomiaDependance} />
      <Route
        path="/en/gastronomia-dependance"
        component={GastronomiaDependance}
      />
      <Route
        path="/sport-wellness-a-relax-dependance"
        component={SportWellnessARelaxDependance}
      />
      <Route
        path="/en/sport-wellness-a-relax-dependance"
        component={SportWellnessARelaxDependance}
      />
      <Route path="/foto-dependance" component={FotoDependance} />
      <Route path="/en/foto-dependance" component={FotoDependance} />
      <Route
        path="/volny-cas-v-okoli-dependance"
        component={VolnyCasVOkoliDependance}
      />
      <Route
        path="/en/volny-cas-v-okoli-dependance"
        component={VolnyCasVOkoliDependance}
      />
      <Route path="/kontakt-dependance" component={KontaktDependance} />
      <Route path="/en/kontakt-dependance" component={KontaktDependance} />
      <Route path="/apartman-centrum" component={Room} />
      <Route path="/en/apartman-centrum" component={Room} />
      <Route path="/jednolozkova-izba-centrum" component={Room} />
      <Route path="/en/jednolozkova-izba-centrum" component={Room} />
      <Route
        path="/dvojlozkova-izba-rozdelene-lozka-centrum"
        component={Room}
      />
      <Route
        path="/en/dvojlozkova-izba-rozdelene-lozka-centrum"
        component={Room}
      />
      <Route
        path="/dvojlozkova-izba-manzelska-postel-centrum"
        component={Room}
      />
      <Route
        path="/en/dvojlozkova-izba-manzelska-postel-centrum"
        component={Room}
      />
      <Route path="/apartman-dependance" component={Room} />
      <Route path="/en/apartman-dependance" component={Room} />
      <Route path="/izba-c-1-dependance" component={Room} />
      <Route path="/en/izba-c-1-dependance" component={Room} />
      <Route path="/izba-c-2-dependance" component={Room} />
      <Route path="/en/izba-c-2-dependance" component={Room} />
      <Route path="/izba-c-3-dependance" component={Room} />
      <Route path="/en/izba-c-3-dependance" component={Room} />
      <Route path="/izba-c-4-dependance" component={Room} />
      <Route path="/en/izba-c-4-dependance" component={Room} />

      <Route component={Home} />
    </Switch>
  )
}

export default AppRouter
