import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form, Field, useFormikContext } from 'formik'
import * as Yup from 'yup'
import parse from 'html-react-parser'
import MyCheckbox from '../Reservation/MyCheckbox'
import MyCheckboxGroup from '../Reservation/MyCheckboxGroup'
import MySingleCheckbox from '../Reservation/MySingleCheckbox'
import MyTextInput from '../Reservation/MyTextInput'
import MyTextArea from '../Reservation/MyTextArea'
import MyDateRangePickerField from '../Reservation/MyDateRangePickerField'
import { sendReservationAsync } from '../../actions/reservations'
import { unselectRoom } from '../../actions/reservations'
import Underline from '../Utils/Underline'
import useFetchText from '../../hooks/useFetchText'
import { queryStringRezervacia } from '../../wordpress/wordpress'
import Loading from '../Utils/Loading'
import ErrorWithSpacer from '../Reservation/ErrorWithSpacer'

const SubmitButton = ({ text }) => {
  const { isSubmitting, status } = useFormikContext()
  return (
    <React.Fragment>
      {!!status && status.status === 'ok' ? (
        <div className="row button__wrapper">
          <button className="button button__button" type="reset">
            {text.nova_rezervacia}
          </button>
        </div>
      ) : (
        <div className="row button__wrapper">
          <button
            className="button button__button"
            type="submit"
            disabled={isSubmitting || (!!status && status.status === 'ok')}
          >
            {text.rezervovat}
          </button>
        </div>
      )}

      {!!status && status.status === 'ok' && (
        <p className="sent-message sent-message--ok">
          {text.spravy.uspesna_rezervacia}
        </p>
      )}
      {!!status && status.status === 'failed' && (
        <p className="sent-message sent-message--failed">
          {text.spravy.neuspesna_rezervacia}
        </p>
      )}
    </React.Fragment>
  )
}

const Rezervacia = () => {
  const text = useFetchText(queryStringRezervacia)
  const selectedRoom = useSelector((state) => state.reservations)
  /*
  Set reservations on component unmount to '' so that when the user 
  leaves Rezervacia page to e.g. Kontakt and comes 
  back there will be no checkbox selected.
  */
  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch(unselectRoom())
    }
  }, [])

  const formatValues = (values) => ({
    ...values,
    startDate: values.startDate.format('YYYY-MM-DD'),
    endDate: values.endDate.format('YYYY-MM-DD'),
    izby: values.izby.filter((el) => el !== '' || undefined || null),
    klimatizacia: `${values.klimatizacia ? 'Áno' : 'Nie'}`,
  })

  const [shouldResetDate, setShouldResetDate] = useState(false)

  const initialValues = {
    yourName: '',
    email: '',
    phone: '',
    izby: [selectedRoom],
    pocetDospelych: '',
    pocetDeti: '',
    pristelka: '',
    domaceZviera: '',
    klimatizacia: false,
    yourMessage: '',
    startDate: null,
    endDate: null,
  }
  const validationSchema = Yup.object({
    yourName: Yup.string()
      .max(50, (text.isLoaded && text.spravy.prilis_dlhe) || 'Wrong input')
      .required((text.isLoaded && text.spravy.povinne) || 'Required'),
    email: Yup.string()
      .email(
        (text.isLoaded && text.spravy.neplatna_emailova_adresa) || 'Wrong input'
      )
      .required((text.isLoaded && text.spravy.povinne) || 'Required'),
    phone: Yup.string()
      .matches(
        /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g,
        (text.isLoaded && text.spravy.neplatne_telefonne_cislo) || 'Wrong input'
      )
      .min(4, (text.isLoaded && text.spravy.prilis_kratke) || 'Wrong input')
      .max(20, (text.isLoaded && text.spravy.prilis_dlhe) || 'Wrong input')
      .required((text.isLoaded && text.spravy.povinne) || 'Required'),
    izby: Yup.array()
      .compact() // Removes default value '' from reservationsReducer
      .min(1, (text.isLoaded && text.spravy.povinne) || 'Required')
      .required((text.isLoaded && text.spravy.povinne) || 'Required'),
    pocetDospelych: Yup.number()
      .min(1, (text.isLoaded && text.spravy.prilis_nizke) || 'Wrong input')
      .max(100, (text.isLoaded && text.spravy.prilis_vysoke) || 'Wrong input')
      .required((text.isLoaded && text.spravy.povinne) || 'Required'),
    pocetDeti: Yup.number()
      .min(0, (text.isLoaded && text.spravy.prilis_nizke) || 'Wrong input')
      .max(100, (text.isLoaded && text.spravy.prilis_vysoke) || 'Wrong input'),
    pristelka: Yup.number()
      .min(0, (text.isLoaded && text.spravy.prilis_nizke) || 'Wrong input')
      .max(100, (text.isLoaded && text.spravy.prilis_vysoke) || 'Wrong input'),
    domaceZviera: Yup.number()
      .min(0, (text.isLoaded && text.spravy.prilis_nizke) || 'Wrong input')
      .max(100, (text.isLoaded && text.spravy.prilis_vysoke) || 'Wrong input'),
    klimatizacia: Yup.boolean().oneOf([true, false], 'Wrong input'),
    yourMessage: Yup.string().max(
      500,
      (text.isLoaded && text.spravy.prilis_dlhe) || 'Wrong input'
    ),
    startDate: Yup.date()
      .nullable()
      .required((text.isLoaded && text.spravy.povinne) || 'Required'),
    endDate: Yup.date()
      .nullable()
      .required((text.isLoaded && text.spravy.povinne) || 'Required'),
  })
  return (
    <React.Fragment>
      {!!text.isLoaded ? (
        <section className="section container">
          <h1 className="heading-1 margin-top-medium-gap">{text.nadpis}</h1>
          <Underline />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            // Submit function
            // setTimeout to prevent double submission
            // response to generate a message if the e-mail was sent successfully
            onSubmit={(values, { setSubmitting, setStatus }) => {
              const formattedValues = formatValues(values)
              sendReservationAsync(formattedValues).then((response) => {
                setStatus(response)
                setSubmitting(false)
              })
            }}
            onReset={() => {
              setShouldResetDate(true)
            }}
          >
            <Form className="form">
              <MyTextInput
                label={text.meno_a_priezvisko}
                name="yourName"
                type="text"
                placeholder=""
              />
              <MyTextInput
                label={text.email}
                name="email"
                type="email"
                placeholder=""
              />
              <MyTextInput
                label={text.telefonne_cislo}
                name="phone"
                type="tel"
                placeholder=""
              />
              <MyCheckboxGroup name="izby" label={text.izba}>
                {Object.values(text.izby).map((el) => {
                  const personHtml = '<i class="fas fa-male"></i>'
                  const valuePart = el.slice(0, el.indexOf(' ('))
                  const numberOfPersons = el.slice(
                    el.search(/%%{/) + 3,
                    el.search(/}%%/)
                  )
                  const parsedText = `${el.slice(
                    0,
                    el.search(/%%{/)
                  )}${personHtml.repeat(numberOfPersons)})`

                  return (
                    <MyCheckbox name="izby" value={valuePart}>
                      {parse(parsedText)}
                    </MyCheckbox>
                  )
                })}
              </MyCheckboxGroup>

              <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                  <label
                    htmlFor="DateRangePicker"
                    className="form__label form__label--date"
                  >
                    {parse(text.datum_prichodu)}
                  </label>
                  <label
                    htmlFor="DateRangePicker"
                    className="form__label form__label form__label--date"
                  >
                    {parse(text.datum_odchodu)}
                  </label>
                </div>
                <div className="col-12 col-md-6 col-lg-8">
                  <Field
                    component={MyDateRangePickerField}
                    name="MyDateRangePickerField"
                    placeholder={{
                      datum_prichodu: text.datum_prichodu.slice(
                        0,
                        text.datum_prichodu.indexOf(' * ')
                      ),
                      datum_odchodu: text.datum_odchodu.slice(
                        0,
                        text.datum_odchodu.indexOf(' * ')
                      ),
                    }}
                    resetDate={{ shouldResetDate, setShouldResetDate }}
                  />
                </div>
              </div>

              <Field>
                {({ form: { touched }, meta: { error } }) => {
                  if (
                    (touched.startDate && !!error.startDate) ||
                    (touched.endDate && !!error.endDate)
                  ) {
                    return (
                      <ErrorWithSpacer
                        error={error.startDate || error.endDate}
                      />
                    )
                  }
                  return <div></div>
                }}
              </Field>

              <MyTextInput
                label={text.pocet_dospelych}
                name="pocetDospelych"
                type="number"
                placeholder=""
              />

              <MyTextInput
                label={parse(text.pocet_deti)}
                name="pocetDeti"
                type="number"
                placeholder=""
              />

              <MyTextInput
                label={text.pristelka}
                name="pristelka"
                type="number"
                placeholder=""
              />

              <MyTextInput
                label={text.domace_zviera}
                name="domaceZviera"
                type="number"
                placeholder=""
              />

              <MySingleCheckbox
                name="klimatizacia"
                label={parse(text.klimatizacia)}
              >
                {text.klimatizacia_checkbox}
              </MySingleCheckbox>

              <MyTextArea
                label={text.poznamka}
                name="yourMessage"
                placeholder=""
              />

              <SubmitButton text={text} />
            </Form>
          </Formik>
        </section>
      ) : (
        <Loading />
      )}
    </React.Fragment>
  )
}

export default Rezervacia
