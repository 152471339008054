import React from 'react'
import marked from 'marked'
import parse from 'html-react-parser'
import Underline from '../Utils/Underline'

const SectionTextImage = ({ text, imageSrc, sectionRowReverse }) => {
  return (
    <section className="section container">
      {text.nadpis && (
        <h1 className="heading-1" id={text.nadpis}>
          {text.nadpis}
        </h1>
      )}

      <Underline />
      <div
        className={`row ${sectionRowReverse ? 'section__row--reverse' : ''}`}
      >
        <div className="col-12 col-md-6 col-lg-8 text-justify">
          {parse(marked(text.paragraf))}
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          {!Array.isArray(imageSrc) ? (
            <img src={imageSrc} alt="" className="img-fluid" />
          ) : (
            imageSrc.map((el) => {
              return (
                <img
                  src={el}
                  alt=""
                  className="img-fluid section-text-image__2-images"
                />
              )
            })
          )}
        </div>
      </div>
    </section>
  )
}

export { SectionTextImage as default }
