import React, { useEffect } from 'react'
import { useField } from 'formik'
import { useLocation } from 'react-router-dom'

import ErrorWithSpacer from './ErrorWithSpacer'

// Component to group multiple checkboxes
const MyCheckboxGroup = ({ children, label, ...props }) => {
  const [, meta, helpers] = useField({
    ...props,
    type: 'checkbox',
    multiple: true,
  })

  /*
    Run this on pathname change to clear room checkboxes on language change. 
    Otherwise rooms in one language would remain checked and sent in other language as well.
  */
  const { pathname } = useLocation()
  useEffect(() => {
    return () => {
      helpers.setValue([''], false) // value set to initial value in cleanup function!
    }
  }, [pathname])

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-4">
          <label
            htmlFor="checkbox-group"
            id="checkbox-group"
            className="form__label"
          >
            {label}
          </label>
        </div>
        <div
          role="group"
          aria-labelledby="checkbox-group"
          className="col-12 col-md-6 col-lg-8"
        >
          {children}
        </div>
      </div>
      {meta.touched && meta.error ? (
        <ErrorWithSpacer error={meta.error} />
      ) : null}
    </React.Fragment>
  )
}

export { MyCheckboxGroup as default }
