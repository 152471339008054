const navigationReducer = (state = '', action) => {
  switch (action.type) {
    case 'CENTRUM':
      return (state = 'centrum')
    case 'DEPENDANCE':
      return (state = 'dependance')
    case 'HOME':
      return (state = '')
    default:
      return state
  }
}

export { navigationReducer as default }
