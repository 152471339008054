import React from 'react'
import { Link } from 'react-router-dom'

const Button = ({ link, buttonText }) => {
  return (
    <div>
      <Link
        className="button button__link"
        to={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {buttonText}
      </Link>
    </div>
  )
}

export { Button as default }
