const languageReducer = (state = 'SK', { type }) => {
  switch (type) {
    case 'SET_SK':
      return 'SK'
    case 'SET_EN':
      return 'EN'
    default:
      return state
  }
}

export { languageReducer as default }
