import React from 'react'
import { NavLink } from 'react-router-dom'

const NavigationSecondary = ({ text, samePage = true, hotelName }) => {
  return (
    <React.Fragment>
      <nav className="navigation-secondary container">
        <ul className="navigation-secondary__list d-flex justify-content-center flex-lg-row flex-column">
          {samePage &&
            text.map((el) => {
              return (
                <li key={el} className="navigation-secondary__item">
                  <a href={`#${el}`} className="navigation-secondary__link">
                    {el}
                  </a>
                </li>
              )
            })}
          {!samePage &&
            text
              .filter((el) => el.slug.length > 0)
              .map((el) => {
                return (
                  <li key={el.slug} className="navigation-secondary__item">
                    <NavLink
                      to={el.slug}
                      className="navigation-secondary__link"
                    >
                      {el.nadpis}
                    </NavLink>
                  </li>
                )
              })}
        </ul>
      </nav>
    </React.Fragment>
  )
}

export { NavigationSecondary as default }
