import React from 'react'
// import useDispatchHotelName from '../../hooks/useDispatchHotelName'
import useFetchText from '../../hooks/useFetchText'
import { queryStringKontakt } from '../../wordpress/wordpress'
import SectionKontaktHotel from '../Sections/SectionKontaktHotel'
import SectionKontaktVzdialenosti from '../Sections/SectionKontaktVzdialenosti'
import Underline from '../Utils/Underline'
import Loading from '../Utils/Loading'

const KontaktCentrum = () => {
  const hotelName = 'centrum'
  // useDispatchHotelName(hotelName)
  const text = useFetchText(queryStringKontakt)
  return (
    <section className="section container page-kontakt margin-top-medium-gap">
      {text.isLoaded ? (
        <React.Fragment>
          <h1 className="heading-1">{text.nadpis}</h1>
          <Underline />
          <div className="row">
            <SectionKontaktHotel text={text.kontakt_centrum} />
            <SectionKontaktHotel text={text.kontakt_dependance} />
            <SectionKontaktVzdialenosti
              vzd_miest={text.vzdialenosti_miest}
              vzd_letisk={text.vzdialenosti_letisk}
            />
          </div>
        </React.Fragment>
      ) : (
        <Loading />
      )}

      <div>
        <iframe
          style={{ border: 0, display: 'inline' }}
          data-cookieconsent="marketing"
          width="100%"
          height="300"
          frameBorder="0"
          allowFullScreen="allowfullscreen"
          className=" cookieconsent-optin-marketing"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2629.2508052623157!2d18.62423991558972!3d48.77710191438101!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel+Squash!5e0!3m2!1sen!2ssk!4v1543772077181"
        ></iframe>
      </div>
    </section>
  )
}

const KontaktDependance = () => {
  const hotelName = 'dependance'
  // useDispatchHotelName(hotelName)
  const text = useFetchText(queryStringKontakt)
  return (
    <section className="section container page-kontakt margin-top-medium-gap">
      {text.isLoaded ? (
        <React.Fragment>
          <h1 className="heading-1">{text.nadpis}</h1>
          <Underline />
          <div className="row">
            <SectionKontaktHotel text={text.kontakt_dependance} />
            <SectionKontaktHotel text={text.kontakt_centrum} />
            <SectionKontaktVzdialenosti
              vzd_miest={text.vzdialenosti_miest}
              vzd_letisk={text.vzdialenosti_letisk}
            />
          </div>
        </React.Fragment>
      ) : (
        <Loading />
      )}
      <div>
        <iframe
          style={{ border: 0, display: 'inline' }}
          data-cookieconsent="marketing"
          width="100%"
          height="300"
          frameBorder="0"
          allowFullScreen="allowfullscreen"
          className=" cookieconsent-optin-marketing"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2629.2488097199775!2d18.62545531558974!3d48.777140014378325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4714ddda32590be3%3A0xe9aa44bbb781dde4!2sHotel+Squash+Dependance!5e0!3m2!1sen!2ssk!4v1547907857428"
        ></iframe>
      </div>
    </section>
  )
}

export { KontaktCentrum, KontaktDependance }
