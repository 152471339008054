import React from 'react'
import Header from '../Header'
import useFetchText from '../../hooks/useFetchText'
import useFetchImages from '../../hooks/useFetchImages'
import SectionTextImage from '../Sections/SectionTextImage'
import SectionText from '../Sections/SectionText'
import SectionTextH3 from '../Sections/SectionTextH3'
import SectionTextTwoColumns from '../Sections/SectionTextTwoColumns'
import RoomCardCentrum from '../RoomCardCentrum'
import RoomCardDependance from '../RoomCardDependance'
import CennikHotelBoth from '../Cenniky/CennikHotelBoth'
import NavigationSecondary from '../Navigations/NavigationSecondary'
import {
  queryStringCennik,
  queryStringHotel,
  queryStringImages,
  searchImagesStrings,
} from '../../wordpress/wordpress'
import Underline from '../Utils/Underline'
import Loading from '../Utils/Loading'
const imagesStringsCentrum = searchImagesStrings.centrum.izby
const imagesStringsDependance = searchImagesStrings.dependance.izby

const HotelSquashCentrum = () => {
  const hotelName = 'centrum'
  const text = useFetchText(`${queryStringHotel}${hotelName}`)
  const cennik = useFetchText(queryStringCennik)
  const [images] = useFetchImages(queryStringImages, [
    imagesStringsCentrum.apartman,
    imagesStringsCentrum.manzelska,
    imagesStringsCentrum.jednolozkoDvojlozko,
  ])
  const imageName = 'hotel-centrum.jpg'
  return (
    <React.Fragment>
      <Header imageName={imageName} />
      {text.isLoaded && cennik.isLoaded && (
        <NavigationSecondary
          text={[
            text.o_hoteli.nadpis,
            text.izby.nadpis,
            text.sluzby.nadpis,
            cennik.nadpis,
          ]}
        />
      )}

      {text.isLoaded ? (
        <React.Fragment>
          <SectionTextImage
            text={text.o_hoteli}
            imageSrc={text.o_hoteli.obrazok.url}
          />
          <SectionText text={text.izby} />
          <Underline />
          <div className="section container">
            {Object.entries(text.karty_izieb).map(([objKey, value]) => {
              if (value.nadpis && value.paragraf) {
                return (
                  <RoomCardCentrum
                    text={value}
                    karta={objKey}
                    images={images}
                    hotelName={hotelName}
                    viac={text.viac}
                    key={value.nadpis}
                  />
                )
              } else {
                return false
              }
            })}
          </div>
          <SectionTextTwoColumns
            text={text.sluzby}
            imageSrc={text.sluzby.obrazok.url}
            hotelName={hotelName}
          />

          {cennik.isLoaded && (
            <CennikHotelBoth text={cennik} hotelName={hotelName} />
          )}
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </React.Fragment>
  )
}

const HotelSquashDependance = () => {
  const hotelName = 'dependance'
  const text = useFetchText(`${queryStringHotel}${hotelName}`)
  const cennik = useFetchText(queryStringCennik)
  const [images] = useFetchImages(queryStringImages, [
    imagesStringsDependance.apartman,
    imagesStringsDependance.izba_c_1,
    imagesStringsDependance.izba_c_2,
    imagesStringsDependance.izba_c_3,
    imagesStringsDependance.izba_c_4,
  ])
  const imageName = 'hotel-dependance.jpg'
  return (
    <React.Fragment>
      <Header imageName={imageName} />
      {text.isLoaded && cennik.isLoaded && (
        <NavigationSecondary
          text={[
            text.o_hoteli.nadpis,
            text.izby.nadpis,
            text.sluzby.nadpis,
            cennik.nadpis,
          ]}
        />
      )}

      {text.isLoaded ? (
        <React.Fragment>
          <SectionTextImage
            text={text.o_hoteli}
            imageSrc={text.o_hoteli.obrazok.url}
          />
          <SectionText text={text.izby} />
          <Underline />
          <div className="section container">
            {Object.entries(text.karty_izieb).map(([objKey, value]) => {
              if (value.nadpis && value.paragraf) {
                return (
                  <RoomCardDependance
                    text={value}
                    karta={objKey}
                    images={images}
                    hotelName={hotelName}
                    viac={text.viac}
                    key={value.nadpis}
                  />
                )
              } else {
                return false
              }
            })}
          </div>
          <SectionTextTwoColumns
            text={text.sluzby}
            imageSrc={text.sluzby.obrazok.url}
            hotelName={hotelName}
          />

          {cennik.isLoaded && (
            <CennikHotelBoth text={cennik} hotelName={hotelName} />
          )}
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </React.Fragment>
  )
}

export { HotelSquashCentrum, HotelSquashDependance }
