import React, { useState, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
const Header = ({ imageName }) => {
  const [inProp, setInProp] = useState(false)
  useEffect(() => {
    setInProp(true)
  }, [])
  const imagePath = `/images/header/1920x700/${imageName}`
  return (
    <CSSTransition
      in={inProp}
      timeout={{ enter: 300, exit: 300 }}
      classNames="react-header-fade"
    >
      <header className="header">
        <picture className="header__picture">
          <img srcSet={imagePath} src={imagePath} alt="header" />
        </picture>
      </header>
    </CSSTransition>
  )
}

export { Header as default }
