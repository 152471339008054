import { url } from '../wordpress/wordpress'

const setText = (text = {}) => ({
  type: 'SET_TEXT',
  text,
})

const fetchText = (endpointAPI, endpointJS) => {
  return (dispatch) => {
    const fetchData = async () => {
      try {
        // Compose fullUrl, fetch data, parse fetched data
        const fullUrl = `${url}${endpointAPI}`
        const data = await fetch(fullUrl)
        let jsonData = await data.json()
        let slug = jsonData[0].slug

        // Remove '-en' from slug if present
        slug.lastIndexOf('-en') === slug.length - 3 &&
          (slug = slug.replace('-en', ''))

        jsonData = jsonData[0].acf
        jsonData = { ...jsonData, slug, isLoaded: true }
        ////////////////////////////////////////////////////////////////////////////////////////////
        // Validate the fetched data
        // Check if the data is ok otherwise return false
        if (!data.ok) {
          return false
        }
        const dataValidation = () => {
          // Get values from the 'acf' object and place them in an array
          let valuesArr = Object.values(jsonData)
          // Check for objects inside the data and convert them into arrays
          valuesArr = valuesArr.map((val) => {
            if (
              typeof val === 'object' &&
              val !== null &&
              !Array.isArray(val)
            ) {
              return Object.values(val)
            } else {
              return val
            }
          })
          // Create 1 non-nested array
          const unNest = (valuesArr) => {
            let pure = valuesArr.filter((el) => !Array.isArray(el))
            let arrs = valuesArr.filter((el) => Array.isArray(el))
            return pure.concat(arrs.reduce((acc, val) => [...acc, ...val], ''))
          }
          valuesArr = unNest(valuesArr)
          // Convert array elements to strings
          const toString = () =>
            valuesArr.map((val) => {
              if (val !== null) {
                return val.toString()
              } else {
                return null
              }
            })
          valuesArr = toString(valuesArr)
          // Rules for validation
          const filteredArr = valuesArr.filter((val) => val.length >= 0)

          // Return
          if (filteredArr.length === valuesArr.length) {
            return true
          } else {
            console.log(`The data from ${endpointAPI} is invalid!`)
            return false
          }
        }
        const isValidData = dataValidation()
        ////////////////////////////////////////////////////////////////////////////////////////////

        // Wrap the fetched data in an object to be able to use ...spread operator in the reducer and basicaly 'push' fetched data in state.texts
        const objectData = {
          [endpointJS]: jsonData,
        }
        if (isValidData) {
          dispatch(setText(objectData))
        }
      } catch (error) {
        console.log(`Error fetching data from ${endpointAPI}: `, error)
      }
    }
    fetchData()
  }
}

export { setText, fetchText }
