import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchImages } from '../actions/images'

const useFetchImages = (queryStringImages, searchImagesStringsArr) => {
  const dispatch = useDispatch()
  const images = useSelector((state) => state.images)
  const namesArr = searchImagesStringsArr.map((el) => {
    return el.replace(/-|\//g, '')
  })

  const areLoaded = namesArr
    .map((el) => {
      return images[el].length > 0
    })
    .reduce((acc, cur) => {
      return acc && cur
    })
  useEffect(() => {
    if (!areLoaded) {
      dispatch(fetchImages(queryStringImages, searchImagesStringsArr, dispatch))
    }
  }, [searchImagesStringsArr])

  return [images, namesArr]
}

export { useFetchImages as default }
