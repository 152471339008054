import React from 'react'
import { useField } from 'formik'

import ErrorWithSpacer from './ErrorWithSpacer'

// Single checkbox (for klimatizacia)
const MySingleCheckbox = ({ children, label, ...props }) => {
  const [field, meta] = useField({
    ...props,
    type: 'checkbox',
  })
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-4">
          <label htmlFor={props.name} className="form__label">
            {label}
          </label>
        </div>
        <div className="col-12 col-md-6 col-lg-8">
          <label className="checkbox form__checkbox-label">
            <input
              type="checkbox"
              className="form__checkbox-input"
              {...field}
              {...props}
            />
            {children}
          </label>
        </div>
      </div>
      {meta.touched && meta.error ? (
        <ErrorWithSpacer error={meta.error} />
      ) : null}
    </React.Fragment>
  )
}

export { MySingleCheckbox as default }
