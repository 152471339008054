import React from 'react'
import parse from 'html-react-parser'

const SectionKontaktHotel = ({ text }) => {
  return (
    <div className="col-12 col-lg-6">
      <div>
        <h2 className="heading-2">{text.nadpis}</h2>
        <p>{parse(text.adresa)}</p>
        <p>{parse(text.gps)}</p>
      </div>
      <div>
        <h3 className="heading-3">{text.kontakt.nadpis}</h3>
        <p>
          {text.kontakt.mobil_1} <br />
          {text.kontakt.mobil_2} <br />
          {parse(text.kontakt.mobil_3)} <br />
          {text.kontakt.email}
        </p>
      </div>
    </div>
  )
}

export { SectionKontaktHotel as default }
