import React from 'react'
import parse from 'html-react-parser'
import moment from 'moment'
import Underline from '../Utils/Underline'

const SectionDenneMenu = ({
  datum,
  den_1,
  den_2,
  den_3,
  den_4,
  den_5,
  den_6,
  dlzka_tyzdna,
  info,
  nadpis,
  paragraf,
}) => {
  const startDate = moment(datum)
  const endDate = moment(startDate).add(parseInt(dlzka_tyzdna) - 1, 'days')
  return (
    <section className="section section-denne-menu container">
      <h1 id={nadpis}>{nadpis}</h1>
      <Underline />
      <div className="row">
        <div className="col-12">
          <p>{parse(paragraf)}</p>
        </div>
      </div>
      <div className="row info">
        <div className="col-4">
          <strong>
            {startDate.format('D.M.')} - {endDate.format('D.M.YYYY')}
          </strong>
        </div>
        <div className="col-8">{parse(info)}</div>
      </div>
      {[den_1, den_2, den_3, den_4, den_5].map((el) => {
        return (
          <div className="section-denne-menu__den">
            <div className="row section-denne-menu__riadok">
              <div className="col-4">
                <strong>{el.den}</strong>
              </div>
              <div className="col-8">{el.polievka}</div>
            </div>
            {Object.values(el.hlavne_jedla).map((el) => {
              return (
                <div className="row section-denne-menu__riadok">
                  <div className="col-12 col-sm-4">{el.vaha}</div>
                  <div className="col-12 col-sm-6">{el.jedlo}</div>
                  <div className="col-12 col-sm-2 text-right">{el.cena}</div>
                </div>
              )
            })}
          </div>
        )
      })}
      {den_6.zobrazit && (
        <div className="section-denne-menu__den">
          <div className="row section-denne-menu__riadok">
            <div className="col-3">
              <strong>{den_6.den}</strong>
            </div>
            <div className="col-9">{den_6.polievka}</div>
          </div>
          {Object.values(den_6.hlavne_jedla).map((el) => {
            return (
              <div className="row section-denne-menu__riadok">
                <div className="col-3">{el.vaha}</div>
                <div className="col-7">{el.jedlo}</div>
                <div className="col-2 text-right">{el.cena}</div>
              </div>
            )
          })}
        </div>
      )}
    </section>
  )
}

export { SectionDenneMenu as default }
