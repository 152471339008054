const textReducerDefaults = {
  navigacia: {
    link_1: 'Hotel',
    link_2: 'Gastronómia',
    link_3: 'Šport, wellness a relax',
    link_4: 'Spoločenské a firemné akcie',
    link_5: 'Foto',
    link_6: 'Voľný čas v okolí',
    link_7: 'Kontakt',
    link_8: 'Rezervácia',
    slug: 'navigacia',
    isLoaded: true,
  },
  hotelcentrum: { isLoaded: false },
  hoteldependance: { isLoaded: false },
  apartmancentrum: { isLoaded: false },
  jednolozkovaizbacentrum: { isLoaded: false },
  dvojlozkovaizbarozdelenelozkacentrum: { isLoaded: false },
  dvojlozkovaizbamanzelskapostelcentrum: { isLoaded: false },
  apartmandependance: { isLoaded: false },
  izbac1dependance: { isLoaded: false },
  izbac2dependance: { isLoaded: false },
  izbac3dependance: { isLoaded: false },
  izbac4dependance: { isLoaded: false },
  gastronomia: { isLoaded: false },
  jedalnylistok: { isLoaded: false },
  napojovylistok: { isLoaded: false },
  vinnakarta: { isLoaded: false },
  dennemenu: { isLoaded: false },
  sportwellnessarelaxcentrum: { isLoaded: false },
  sportwellnessarelaxdependance: { isLoaded: false },
  cennikwellnessasport: { isLoaded: false },
  cennik: { isLoaded: false },
  spolocenskeafiremneakciecentrum: { isLoaded: false },
  volnycasvokoli: { isLoaded: false },
  fotocentrum: { isLoaded: false },
  fotodependance: { isLoaded: false },
  kontakt: { isLoaded: false },
  rezervacia: { isLoaded: false },
  footercentrum: { isLoaded: false },
  footerdependance: { isLoaded: false },
  // EN language
  navigaciaen: {
    link_1: 'Hotel',
    link_2: 'Gastronomy',
    link_3: 'Sport, wellness and relax',
    link_4: 'Social and corporate events',
    link_5: 'Photo',
    link_6: 'Free time in the area',
    link_7: 'Contact',
    link_8: 'Book a room',
    slug: 'navigacia',
    isLoaded: true,
  },
  hotelcentrumen: { isLoaded: false },
  hoteldependanceen: { isLoaded: false },
  apartmancentrumen: { isLoaded: false },
  jednolozkovaizbacentrumen: { isLoaded: false },
  dvojlozkovaizbarozdelenelozkacentrumen: { isLoaded: false },
  dvojlozkovaizbamanzelskapostelcentrumen: { isLoaded: false },
  apartmandependanceen: { isLoaded: false },
  izbac1dependanceen: { isLoaded: false },
  izbac2dependanceen: { isLoaded: false },
  izbac3dependanceen: { isLoaded: false },
  izbac4dependanceen: { isLoaded: false },
  gastronomiaen: { isLoaded: false },
  jedalnylistoken: { isLoaded: false },
  napojovylistoken: { isLoaded: false },
  vinnakartaen: { isLoaded: false },
  dennemenuen: { isLoaded: false },
  sportwellnessarelaxcentrumen: { isLoaded: false },
  sportwellnessarelaxdependanceen: { isLoaded: false },
  cennikwellnessasporten: { isLoaded: false },
  cenniken: { isLoaded: false },
  spolocenskeafiremneakciecentrumen: { isLoaded: false },
  volnycasvokolien: { isLoaded: false },
  fotocentrumen: { isLoaded: false },
  fotodependanceen: { isLoaded: false },
  kontakten: { isLoaded: false },
  rezervaciaen: { isLoaded: false },
  footercentrumen: { isLoaded: false },
  footerdependanceen: { isLoaded: false },
}

const textReducer = (state = textReducerDefaults, { type, text }) => {
  switch (type) {
    case 'SET_TEXT':
      return (state = {
        ...state,
        ...text,
      })
    default:
      return state
  }
}

export { textReducer }
