import React from 'react'
import Underline from '../Utils/Underline'
import Loading from '../Utils/Loading'

const CennikHotelBoth = ({ text, hotelName }) => {
  return (
    <React.Fragment>
      {text.isLoaded ? (
        <section className="section  container">
          <h1 className="heading-1" id={text.nadpis}>
            {text.nadpis}
          </h1>
          <Underline />
          {hotelName === 'centrum' && (
            <React.Fragment>
              <h2 className="heading-2 cennik__heading-2">
                {text.cennik_centrum.hotel}
              </h2>
              <table className="cennik-table">
                <colgroup>
                  <col className="cennik-table__col-1" />
                  <col className="cennik-table__col-2" />
                </colgroup>
                <thead className="cennik-table__head">
                  <tr>
                    <th>{text.cennik_centrum.izby.nadpis}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="cennik-table__body">
                  {Object.values(text.cennik_centrum.izby).map((el) => {
                    if (el.izba && el.cena) {
                      return (
                        <tr>
                          <td>{el.izba}</td>
                          <td>{el.cena}</td>
                        </tr>
                      )
                    } else {
                      return null
                    }
                  })}
                </tbody>
              </table>
              <table className="cennik-table">
                <colgroup>
                  <col className="cennik-table__col-1" />
                  <col className="cennik-table__col-2" />
                </colgroup>
                <thead className="cennik-table__head">
                  <tr>
                    <th>{text.cennik_centrum.doplnkove_sluzby.nadpis}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="cennik-table__body">
                  {Object.values(text.cennik_centrum.doplnkove_sluzby).map(
                    (el) => {
                      if (el.sluzba && el.cena) {
                        return (
                          <tr>
                            <td>{el.sluzba}</td>
                            <td>{el.cena}</td>
                          </tr>
                        )
                      } else {
                        return null
                      }
                    }
                  )}
                </tbody>
              </table>
              <Underline />
            </React.Fragment>
          )}

          <React.Fragment>
            <h2 className="heading-2 cennik__heading-2">
              {text.cennik_dependance.hotel}
            </h2>
            <table className="cennik-table">
              <colgroup>
                <col className="cennik-table__col-1" />
                <col className="cennik-table__col-2" />
              </colgroup>
              <thead className="cennik-table__head">
                <tr>
                  <th>{text.cennik_dependance.izby.nadpis}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="cennik-table__body">
                {Object.values(text.cennik_dependance.izby).map((el) => {
                  if (el.izba && el.cena) {
                    return (
                      <tr>
                        <td>{el.izba}</td>
                        <td>{el.cena}</td>
                      </tr>
                    )
                  } else {
                    return null
                  }
                })}
              </tbody>
            </table>
            <table className="cennik-table">
              <colgroup>
                <col className="cennik-table__col-1" />
                <col className="cennik-table__col-2" />
              </colgroup>
              <thead className="cennik-table__head">
                <tr>
                  <th>{text.cennik_dependance.doplnkove_sluzby.nadpis}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="cennik-table__body">
                {Object.values(text.cennik_dependance.doplnkove_sluzby).map(
                  (el) => {
                    if (el.sluzba && el.cena) {
                      return (
                        <tr>
                          <td>{el.sluzba}</td>
                          <td>{el.cena}</td>
                        </tr>
                      )
                    } else {
                      return null
                    }
                  }
                )}
              </tbody>
            </table>
          </React.Fragment>

          {hotelName === 'dependance' && (
            <React.Fragment>
              <Underline />
              <h2 className="heading-2 cennik__heading-2">
                {text.cennik_centrum.hotel}
              </h2>
              <table className="cennik-table">
                <colgroup>
                  <col className="cennik-table__col-1" />
                  <col className="cennik-table__col-2" />
                </colgroup>
                <thead className="cennik-table__head">
                  <tr>
                    <th>{text.cennik_centrum.izby.nadpis}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="cennik-table__body">
                  {Object.values(text.cennik_centrum.izby).map((el) => {
                    if (el.izba && el.cena) {
                      return (
                        <tr>
                          <td>{el.izba}</td>
                          <td>{el.cena}</td>
                        </tr>
                      )
                    } else {
                      return null
                    }
                  })}
                </tbody>
              </table>
              <table className="cennik-table">
                <colgroup>
                  <col className="cennik-table__col-1" />
                  <col className="cennik-table__col-2" />
                </colgroup>
                <thead className="cennik-table__head">
                  <tr>
                    <th>{text.cennik_centrum.doplnkove_sluzby.nadpis}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="cennik-table__body">
                  {Object.values(text.cennik_centrum.doplnkove_sluzby).map(
                    (el) => {
                      if (el.sluzba && el.cena) {
                        return (
                          <tr>
                            <td>{el.sluzba}</td>
                            <td>{el.cena}</td>
                          </tr>
                        )
                      } else {
                        return null
                      }
                    }
                  )}
                </tbody>
              </table>
            </React.Fragment>
          )}
        </section>
      ) : (
        <Loading />
      )}
    </React.Fragment>
  )
}

export { CennikHotelBoth as default }
