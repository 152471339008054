const setHotelNameCentrum = () => ({
    type: 'CENTRUM'
})

const setHotelNameDependance = () => ({
    type: 'DEPENDANCE'
})

const setHotelNameHome = () => ({
    type: 'HOME'
})

export { setHotelNameCentrum, setHotelNameDependance, setHotelNameHome }