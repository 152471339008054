import { url, queryStringDenneMenuFilter } from '../wordpress/wordpress'
import { fetchText } from '../actions/texts'

const filterDenneMenu = (endpointAPI, endpointJS) => {
  return (dispatch) => {
    const fetchData = async () => {
      // fetch data
      const data = await fetch(`${url}${queryStringDenneMenuFilter}`)
      const jsonData = await data.json()

      /*Choose only 1 'Denne menu': 
    1) If there's exactly 1 'Denne menu' with 'zobrazit: true' 
    then extract the object from the array
    2) Else choose 'Denne menu' with the newest date  
    */
      let filteredData = jsonData.filter((el) => el.acf.zobrazit)
      if (filteredData.length === 0) {
        filteredData = jsonData.reduce((acc, cur) => {
          if (Date.parse(acc.acf.datum) < Date.parse(cur.acf.datum)) {
            return cur
          } else return acc
        })
      } else if (filteredData.length > 1) {
        filteredData = filteredData.reduce((acc, cur) => {
          if (Date.parse(acc.acf.datum) < Date.parse(cur.acf.datum)) {
            return cur
          } else return acc
        })
      } else {
        filteredData = filteredData[0]
      }
      // Extract slug and create query string

      const queryString = `${endpointAPI}${filteredData.slug}`
      dispatch(fetchText(queryString, endpointJS))
    }
    fetchData()
  }
}

export { filterDenneMenu }
