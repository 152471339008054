import React from 'react'
import marked from 'marked'
import parse from 'html-react-parser'
import Underline from '../Utils/Underline'

const SectionTextImageMap = ({ text, imageSrc, googleMap }) => {
  return (
    <React.Fragment>
      <section className="section section-text-image-map container">
        {text.nadpis && (
          <h1 className="heading-1" id={text.nadpis}>
            {text.nadpis}
          </h1>
        )}

        <Underline />
        <div class="row text-justify">
          <div class="col-12 col-md-6 px-4 py-2">
            <img class="img-fluid" src={imageSrc[0]} alt="" />
          </div>
          <div class="col-12 col-md-6 px-4 py-2">
            {parse(marked(text.paragraf))}
          </div>
          <div class="col-12 col-md-6 px-4 py-2">
            <img class="img-fluid" src={imageSrc[1]} alt="" />
          </div>
          <div
            class="col-12 col-md-6 px-4 py-2"
            dangerouslySetInnerHTML={{ __html: googleMap }}
          ></div>
        </div>
      </section>
    </React.Fragment>
  )
}

export { SectionTextImageMap as default }
