import React from 'react'
import Header from '../Header'
import useFetchText from '../../hooks/useFetchText'
import NavigationSecondary from '../Navigations/NavigationSecondary'
import SectionTextImage from '../Sections/SectionTextImage'
import SectionTextImageInRows from '../Sections/SectionTextImageInRows'
import SectionText from '../Sections/SectionText'
import { queryStringSpolocenskeAkcie } from '../../wordpress/wordpress'
import Loading from '../Utils/Loading'

const SpolocenskeAFiremneAkcieCentrum = () => {
  const text = useFetchText(queryStringSpolocenskeAkcie)
  const imageName = 'spolocenske-a-firemne-akcie.jpg'
  return (
    <React.Fragment>
      <Header imageName={imageName} />
      {text.isLoaded ? (
        <React.Fragment>
          <NavigationSecondary
            text={[
              text.spolocenske_a_firemne_akcie.nadpis,
              text.vybavenie.nadpis,
              text.priestory.nadpis,
              text.ubytovanie.nadpis,
              text.strava.nadpis,
            ]}
          />
          <SectionTextImage
            text={text.spolocenske_a_firemne_akcie}
            imageSrc={text.spolocenske_a_firemne_akcie.obrazok.url}
          />
          <SectionText text={text.vybavenie} classNames={'section-vybavenie'} />
          <SectionTextImageInRows
            text={text.priestory}
            imageSrc={text.priestory.obrazok}
          />
          <SectionText text={text.ubytovanie} />
          <SectionTextImage
            text={text.strava}
            imageSrc={text.strava.obrazok.url}
            sectionRowReverse={true}
          />
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </React.Fragment>
  )
}

export { SpolocenskeAFiremneAkcieCentrum }
