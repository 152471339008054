import React from 'react'
// import useDispatchHotelName from '../../hooks/useDispatchHotelName'
import useFetchText from '../../hooks/useFetchText'
import {
  queryStringSportWellness,
  queryStringCennikWellnessASport,
  queryStringHotel,
} from '../../wordpress/wordpress'
import SectionTextImage from '../Sections/SectionTextImage'
import SectionWanDeeDee from '../Sections/SectionWanDeeDee'
import CennikOther from '../Cenniky/CennikOther'
import Header from '../Header'
import NavigationSecondary from '../Navigations/NavigationSecondary'
import Loading from '../Utils/Loading'
import Underline from '../Utils/Underline'

const SportWellnessARelaxCentrum = () => {
  const hotelName = 'centrum'
  // useDispatchHotelName(hotelName)
  const text = useFetchText(`${queryStringSportWellness}${hotelName}`)
  const textHotel = useFetchText(`${queryStringHotel}${hotelName}`)
  const cennik = useFetchText(queryStringCennikWellnessASport)
  const imageName = 'sport-wellness-a-relax.jpg'
  return (
    <React.Fragment>
      <Header imageName={imageName} />
      {text.isLoaded ? (
        <React.Fragment>
          <NavigationSecondary
            text={[text.wellness.nadpis, text.sport.nadpis, text.masaze.nadpis]}
          />
          <SectionTextImage
            text={text.wellness}
            imageSrc={[
              text.wellness.obrazok_1.url,
              text.wellness.obrazok_2.url,
            ]}
            sectionRowReverse={true}
          />
          <SectionWanDeeDee
            text={text.masaze}
            imageSrc={[text.masaze.obrazok_1.url, text.masaze.obrazok_2.url]}
            googleMap={
              '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d2629.2083172436246!2d18.624692915915823!3d48.777913114323525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel%20Squash%20Centrum%2C%20Necpalsk%C3%A1%20cesta%20%C4%8D.%2016%2C%20971%2001%20Prievidza%2C%20Slovensko!3m2!1d48.7770843!2d18.626557299999998!4m5!1s0x4714dd856206aaeb%3A0xe1cbbb6e39f5bb07!2sWAN%20DEE%20DEE%20-%20Thajsk%C3%A9%20mas%C3%A1%C5%BEe%2C%20Necpalsk%C3%A1%20cesta%2C%20Prievidza%2C%20Slovensko!3m2!1d48.778565099999994!2d18.6276337!5e0!3m2!1scs!2scz!4v1599996774442!5m2!1scs!2scz" width="100%" height="100%" frameborder="0" style="border: 0;" allowfullscreen="" ariaHidden="false" tabindex="0" title="wandeedeemap"></iframe>'
            }
          />

          <Underline />
          {cennik.isLoaded && <CennikOther text={cennik.cennik_wellness} />}
          <SectionTextImage
            text={text.sport}
            imageSrc={text.sport.obrazok_1.url}
          />

          <Underline />
          {cennik.isLoaded && (
            <React.Fragment>
              <CennikOther text={cennik.cennik_sport} />
              <CennikOther text={cennik.pozicovna} />
              <CennikOther text={cennik.cennik_squash_permanentka} />
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </React.Fragment>
  )
}

const SportWellnessARelaxDependance = () => {
  const hotelName = 'dependance'
  // useDispatchHotelName(hotelName)
  const text = useFetchText(`${queryStringSportWellness}${hotelName}`)
  const textHotel = useFetchText(`${queryStringHotel}${hotelName}`)
  const cennik = useFetchText(queryStringCennikWellnessASport)
  const imageName = 'sport-wellness-a-relax.jpg'
  return (
    <React.Fragment>
      <Header imageName={imageName} />
      {text.isLoaded ? (
        <React.Fragment>
          <NavigationSecondary
            text={[text.blok_1.nadpis, text.masaze.nadpis]}
          />
          <SectionTextImage
            text={text.blok_1}
            imageSrc={text.blok_1.obrazok.url}
            sectionRowReverse={true}
          />
          <SectionTextImage
            text={text.blok_2}
            imageSrc={text.blok_2.obrazok.url}
          />
          <SectionWanDeeDee
            text={text.masaze}
            imageSrc={[text.masaze.obrazok_1.url, text.masaze.obrazok_2.url]}
            googleMap={
              '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d2629.2083172436246!2d18.624692915915823!3d48.777913114323525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel%20Squash%20Centrum%2C%20Necpalsk%C3%A1%20cesta%20%C4%8D.%2016%2C%20971%2001%20Prievidza%2C%20Slovensko!3m2!1d48.7770843!2d18.626557299999998!4m5!1s0x4714dd856206aaeb%3A0xe1cbbb6e39f5bb07!2sWAN%20DEE%20DEE%20-%20Thajsk%C3%A9%20mas%C3%A1%C5%BEe%2C%20Necpalsk%C3%A1%20cesta%2C%20Prievidza%2C%20Slovensko!3m2!1d48.778565099999994!2d18.6276337!5e0!3m2!1scs!2scz!4v1599996774442!5m2!1scs!2scz" width="100%" height="100%" frameborder="0" style="border: 0;" allowfullscreen="" ariaHidden="false" tabindex="0" title="wandeedeemap"></iframe>'
            }
          />

          <Underline />
          {cennik.isLoaded && (
            <React.Fragment>
              <CennikOther text={cennik.cennik_wellness} />
              <CennikOther text={cennik.cennik_sport} />
              <CennikOther text={cennik.pozicovna} />
              <CennikOther text={cennik.cennik_squash_permanentka} />
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </React.Fragment>
  )
}

export { SportWellnessARelaxCentrum, SportWellnessARelaxDependance }
