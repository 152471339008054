import React from 'react'
import { useField } from 'formik'

import ErrorWithSpacer from './ErrorWithSpacer'

// Component for textarea
const MyTextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-4">
          <label htmlFor={props.id || props.name} className="form__label">
            {label}
          </label>
        </div>
        <div className="col-12 col-md-6 col-lg-8">
          <textarea
            className="text-area form__text-area"
            {...field}
            {...props}
          />
        </div>
      </div>
      {meta.touched && meta.error ? (
        <ErrorWithSpacer error={meta.error} />
      ) : null}
    </React.Fragment>
  )
}

export { MyTextArea as default }
