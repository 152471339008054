const reservationsReducer = (state = '', { type, roomName }) => {
  switch (type) {
    case 'SELECT_ROOM':
      return (state = roomName)
    case 'UNSELECT_ROOM':
      return (state = '')
    default:
      return state
  }
}

export { reservationsReducer as default }
