import React from 'react'
import Header from '../Header'
import useFetchText from '../../hooks/useFetchText'
import { queryStringVolnyCasVOkoli } from '../../wordpress/wordpress'
import SectionTextImageMap from '../Sections/SectionTextImageMap'
import { mapsHTMLCentrum, mapsHTMLDependance } from '../../res/maps/mapsHTML'
import Loading from '../Utils/Loading'

const VolnyCasVOkoliCentrum = () => {
  const text = useFetchText(queryStringVolnyCasVOkoli)
  const imageName = 'volny-cas-v-okoli.jpg'
  return (
    <React.Fragment>
      <Header imageName={imageName} />
      {text.isLoaded ? (
        <React.Fragment>
          {Object.values(
            Object.defineProperties(text, {
              isLoaded: { enumerable: false },
              slug: { enumerable: false },
            })
          ).map((el, index) => {
            return (
              <SectionTextImageMap
                text={el}
                imageSrc={el.obrazok}
                sectionRowReverse={index % 2}
                googleMap={mapsHTMLCentrum[index]}
              />
            )
          })}
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </React.Fragment>
  )
}

const VolnyCasVOkoliDependance = () => {
  const text = useFetchText(queryStringVolnyCasVOkoli)
  const imageName = 'volny-cas-v-okoli.jpg'
  return (
    <React.Fragment>
      <Header imageName={imageName} />
      {text.isLoaded ? (
        <React.Fragment>
          {Object.values(
            Object.defineProperties(text, {
              isLoaded: { enumerable: false },
              slug: { enumerable: false },
            })
          ).map((el, index) => {
            return (
              <SectionTextImageMap
                text={el}
                imageSrc={el.obrazok}
                sectionRowReverse={index % 2}
                googleMap={mapsHTMLDependance[index]}
              />
            )
          })}
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </React.Fragment>
  )
}

export { VolnyCasVOkoliCentrum, VolnyCasVOkoliDependance }
