import { urlContactForm7 } from '../wordpress/wordpress'

const sendReservationAsync = async (reservationData) => {
  try {
    let formData = new FormData()
    Object.entries(reservationData).forEach(([key, value]) => {
      formData.append(key, value)
    })
    const requestOptions = {
      method: 'POST',
      body: formData,
      redirect: 'follow',
    }

    const response = await fetch(urlContactForm7, requestOptions)
    const result = await response.json()
    if (result.status === 'mail_sent') {
      return { status: 'ok' }
    } else {
      return {
        status: 'failed',
      }
    }
  } catch (error) {
    console.log(error)
    return { status: 'failed', message: error }
  }
}

const selectRoom = (roomName) => ({
  type: 'SELECT_ROOM',
  roomName,
})

const unselectRoom = () => ({
  type: 'UNSELECT_ROOM',
})

export { sendReservationAsync, selectRoom, unselectRoom }
