import React from 'react'
import Underline from '../Utils/Underline'

const SectionVinnaKarta = ({ text }) => {
  const vinnaKartaArray = Object.values(text.skupiny)
  const idCollapseMain = text.nadpis.replace(/ /g, '')
  return (
    <section className="section container">
      <h1
        className="heading-1 collapse-icon-after"
        id={text.nadpis}
        data-toggle="collapse"
        data-target={`#${idCollapseMain}-collapse`}
        aria-expanded="false"
        aria-controls={`${idCollapseMain}-collapse`}
      >
        {text.nadpis}
      </h1>
      <Underline />
      <div className="collapse" id={`${idCollapseMain}-collapse`}>
        {vinnaKartaArray.map((el, index) => {
          Object.defineProperty(el, 'nadpis', {
            enumerable: false,
          })
          const idCollapse = `${el.nadpis.replace(/ /g, '')}${index}`
          return (
            <React.Fragment>
              <h3
                className="heading-3 collapse-icon-after text-left"
                data-toggle="collapse"
                data-target={`#${idCollapse}-collapse`}
                aria-expanded="false"
                aria-controls={`${idCollapse}-collapse`}
              >
                {el.nadpis}
              </h3>
              <div className="collapse" id={`${idCollapse}-collapse`}>
                {Object.values(el).map((el) => {
                  return (
                    <React.Fragment>
                      <div className="row">
                        <p className="col-8">
                          <strong>{el.nazov_vina}</strong>
                        </p>
                        <p className="col-4 text-right">{el.cena}</p>
                        <p className="col-12">
                          <strong>{el.privlastok}</strong>
                        </p>
                        <p className="col-12">{el.paragraf}</p>
                      </div>
                    </React.Fragment>
                  )
                })}
              </div>
            </React.Fragment>
          )
        })}
      </div>
    </section>
  )
}

export { SectionVinnaKarta as default }
