import React from 'react'
import { Link } from 'react-router-dom'
import SimpleReactLightBox, { SRLWrapper } from 'simple-react-lightbox'
import Carousel from 'react-bootstrap/Carousel'

const RoomCard = ({ text, images, hotelName }) => {
  return (
    <section className="section container">
      <SimpleReactLightBox>
        <div className="room-card row no-gutters">
          <div className="room-card__text-container col-12 col-lg-6">
            <div className="room-card__text-border">
              <h2 className="room-card__heading heading-2">
                <Link to={text.slug} className="room-card__heading--link">
                  {text.nadpis}
                </Link>
              </h2>
              <p className="room-card__text">{text.paragraf}</p>
              <div className="room-card__link"></div>
            </div>
          </div>
          <div className="room-card__image-container col-12 col-lg-6">
            <SRLWrapper>
              <Carousel controls={false} indicators={false}>
                {images.map((el) => {
                  return (
                    <Carousel.Item key={el.media_details.sizes.full.file}>
                      <a href={el.media_details.sizes.full.source_url}>
                        <img
                          src={el.media_details.sizes.large.source_url}
                          className="room-card__image"
                          alt=""
                        />
                      </a>
                    </Carousel.Item>
                  )
                })}
              </Carousel>
            </SRLWrapper>
          </div>
        </div>
      </SimpleReactLightBox>
    </section>
  )
}

export { RoomCard as default }
