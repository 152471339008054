import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import useFetchText from '../hooks/useFetchText'
import { queryStringFooter } from '../wordpress/wordpress'
import Loading from './Utils/Loading'

const Footer = () => {
  const hotelName = useSelector((state) => state.navigation)
  const language = useSelector((state) => state.language)
  const text = useFetchText(`${queryStringFooter}${hotelName}`)

  const googleMapsSrc = {
    centrum:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2629.2508052623157!2d18.624239915589712!3d48.77710191438101!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel+Squash!5e0!3m2!1sen!2ssk!4v1537203376391',
    dependance:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2629.248809719977!2d18.62545531558973!3d48.77714001437833!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4714ddda32590be3%3A0xe9aa44bbb781dde4!2sHotel+Squash+Dependance!5e0!3m2!1sen!2sat!4v1547658181073',
  }
  return (
    <footer className="footer container">
      <div className="footer__wrapper row justify-content-between">
        <div className="footer__box col-12 col-xl-3">
          <img
            srcSet={`/images/logo/logo-${hotelName}.jpg`}
            src={`/images/logo/logo-${hotelName}.jpg`}
            className="footer__logo img-fluid"
            alt="Hotel Squash Logo"
          />
        </div>
        {text.isLoaded ? (
          <React.Fragment>
            <div className="footer__box col-12 col-md-6 col-xl-3">
              <p className="footer__text">
                {text.nazov} <br />
                {text.adresa.ulica} <br />
                {text.adresa.psc_mesto} <br />
                {text.adresa.stat} <br />
                {text.gps.nazov} <br />
                {text.gps.zemepisna_dlzka} <br />
                {text.gps.zemepisna_sirka}
              </p>
            </div>
            <div className="footer__box col-12 col-md-6 col-xl-3">
              <p className="footer__text">
                {text.kontakt.mobil_1} <br />
                {text.kontakt.mobil_2} <br />
                {text.kontakt.mobil_3} <br />
                {text.kontakt.email}
              </p>
            </div>
          </React.Fragment>
        ) : (
          <Loading />
        )}
        <div className="footer__box footer__box--map col-12 col-xl-2">
          <iframe
            className="footer__map "
            title="footerGoogleMap"
            data-cookieconsent="marketing"
            width="100%"
            height="150"
            frameBorder="0"
            style={{ border: 0, display: 'block' }}
            allowFullScreen=""
            src={
              hotelName === 'centrum'
                ? googleMapsSrc.centrum
                : googleMapsSrc.dependance
            }
          ></iframe>
        </div>
      </div>
      <div className="footer__links">
        <Link
          className=""
          to={`/pdf/ochrana-osobnych-udajov-${language.toLowerCase()}.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text.ochrana_osobnych_udajov}
        </Link>
        <p>
          <a href="javascript:Cookiebot.renew()">{text.cookies}</a>
        </p>
      </div>
    </footer>
  )
}

export { Footer as default }
