import React from 'react'
import parse from 'html-react-parser'

const SectionKontaktVzdialenosti = ({ vzd_miest, vzd_letisk }) => {
  return (
    <React.Fragment>
      <div className="col-12 col-lg-6">
        <h3 className="heading-3">{vzd_miest.nadpis}</h3>
        <p>{parse(vzd_miest.paragraf)}</p>
      </div>
      <div className="col-12 col-lg-6">
        <h3 className="heading-3">{vzd_letisk.nadpis}</h3>
        <p>{parse(vzd_letisk.paragraf)}</p>
      </div>
    </React.Fragment>
  )
}

export { SectionKontaktVzdialenosti as default }
