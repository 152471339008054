import 'react-app-polyfill/ie11'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'

import configureStore from './store/configureStore'
import HotelSquashApp from './components/Pages/App'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'react-dates/lib/css/_datepicker.css'
import '@fortawesome/fontawesome-free/css/all.css'
import './styles/main.scss'

const store = configureStore()

// store.subscribe(() => {
//   console.log(store.getState())
// })

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <HotelSquashApp />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
